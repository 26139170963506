import { __rest } from "tslib";
function coinsTransactionNormalize(transaction) {
    const { author_name, user_id } = transaction, otherProps = __rest(transaction, ["author_name", "user_id"]);
    return Object.assign(otherProps, { authorName: author_name, userId: user_id });
}
function transactionArrayNormalyze(array) {
    return array.map(coinsTransactionNormalize);
}
function transactionToServerSave(transactionToSave) {
    const { userId, productId } = transactionToSave, otherProps = __rest(transactionToSave, ["userId", "productId"]);
    return Object.assign(otherProps, { user_id: userId }, productId ? { product_id: productId } : {});
}
function transactionToServerUpdate(transactionToSave) {
    const { userId, productId } = transactionToSave, otherProps = __rest(transactionToSave, ["userId", "productId"]);
    return Object.assign(otherProps, { user_id: userId, product_id: productId });
}
export { coinsTransactionNormalize, transactionArrayNormalyze, transactionToServerSave, transactionToServerUpdate };
