import apiGetMessages from "../api/methods/payouts/apiGetMessages";
import apiGetSalaryRegistries from "../api/methods/payouts/apiGetSalaryRegistries";
import { mapReadMessages } from "../helpers/helpers";
import { writable } from "svelte/store";
const salary = writable({
    total: 0,
    salary_registries: []
});
const unreadMessages = writable([]);
const isLoading = writable(false);
const error = writable(null);
const getSalary = async (id) => {
    isLoading.set(true);
    const result = await apiGetSalaryRegistries();
    if (result.success) {
        salary.set(result.data);
        const salaryIds = result.data.salary_registries.map((registry) => registry.id);
        const registries = await Promise.all(salaryIds.map((id) => apiGetMessages(id)));
        const messages = registries.map((regisrty) => mapReadMessages(id, regisrty.data).length);
        unreadMessages.set(messages);
    }
    else {
        error.set(result.message);
    }
    isLoading.set(false);
};
export const registriesStore = () => {
    return {
        getSalary,
        unreadMessages,
        salary,
        isLoading
    };
};
