import { api } from "api";
export async function apiGetAdjustment() {
    var _a, _b;
    try {
        const { data: { success, message }, } = await api({
            url: '/adjustments',
            method: "GET",
        });
        return { success, data: message };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if ((_b = errorAxios.response) === null || _b === void 0 ? void 0 : _b.data) {
                const { success, message } = errorAxios.response.data;
                return {
                    success,
                    message,
                };
            }
        }
    }
}
