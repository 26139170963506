import apiGetProjectOfficeStats from "../api/methods/auth/apiGetProjectOfficeStats";
import { get, writable } from "svelte/store";
import { Paths } from "../constants/path";
import apiGetAccessCode from "api/methods/auth/apiGetAccessCode";
import apiGetProjectOffice from "../api/methods/auth/apiGetProjectOffice";
import apiLogin from "api/methods/auth/apiLogin";
import apiLogout from "api/methods/auth/apiLogout";
import apiMe from "api/methods/auth/apiMe";
import { push } from "svelte-spa-router";
const LOCAL_STORAGE_TOKEN_KEY = "token";
const isAuth = writable(Boolean(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)));
const authStore = {
    isAuth,
    isGetCodeSuccess: writable(null),
    email: writable(""),
    message: writable(null),
    code: writable(""),
    errorMessage: writable(null),
    userData: writable(null),
    isFetchGetAccessCode: writable(false),
    isFetchSendCode: writable(false),
    isFetchGetMe: writable(false),
    myProjectOffice: writable(null),
    myProjectOfficeStats: writable(null),
    getMyProjectOffice: async (params) => {
        const { message, success, data } = await apiGetProjectOffice(params);
        if (!success) {
            authStore.errorMessage.set(message);
            return;
        }
        authStore.myProjectOffice.set(data);
    },
    getMyProjectOfficeStats: async (query) => {
        const { message, success, data } = await apiGetProjectOfficeStats(query);
        if (!success) {
            authStore.errorMessage.set(message);
            return;
        }
        authStore.myProjectOfficeStats.set(data);
    },
    getAccessCode: async () => {
        authStore.isFetchGetAccessCode.set(true);
        const email = get(authStore.email);
        const { success, message } = await apiGetAccessCode({ email });
        authStore.isGetCodeSuccess.set(success);
        authStore.message.set(message);
        authStore.isFetchGetAccessCode.set(false);
    },
    hasPermission: (permission) => {
        const userPermissions = get(authStore.userData).permissions;
        if (Array.isArray(permission)) {
            return permission.some((perm) => userPermissions.includes(perm));
        }
        else {
            return userPermissions.includes(permission);
        }
    },
    hasRoles: (roles) => {
        const userRoles = get(authStore.userData).roles;
        if (Array.isArray(roles)) {
            return roles.some((role) => userRoles.includes(role));
        }
        else {
            return userRoles.includes(roles);
        }
    },
    sendCode: async () => {
        authStore.isFetchSendCode.set(true);
        const email = get(authStore.email);
        const code = get(authStore.code);
        const { success, errorMessage, accessToken } = await apiLogin({
            code,
            email,
        });
        if (success) {
            localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, `Bearer ${accessToken}`);
            isAuth.set(true);
            await authStore.getMe();
            push(Paths.main);
        }
        else {
            authStore.errorMessage.set(errorMessage);
        }
        authStore.isFetchSendCode.set(false);
    },
    getMe: async () => {
        authStore.isFetchGetMe.set(true);
        const { success, data } = await apiMe();
        if (success) {
            authStore.userData.set(data);
        }
        authStore.isFetchGetMe.set(false);
    },
    logout: async () => {
        const { success } = await apiLogout();
        if (success) {
            authStore.clearToken();
            isAuth.set(false);
            push(Paths.auth);
            authStore.clear();
        }
    },
    clearToken: () => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    },
    clear: () => {
        authStore.userData.set(null);
        authStore.email.set("");
        authStore.code.set("");
        authStore.isGetCodeSuccess.set(null);
        authStore.message.set(null);
        authStore.errorMessage.set(null);
    },
};
export { authStore };
