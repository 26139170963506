<script lang="ts">
  import { Icon } from "@smui/common";
  import { link } from "svelte-spa-router";
  import type { IProjectNormalize } from "interfaces/Project.interface";
  import Managers from "../../../shared/Managers/index.svelte";

  export let item: IProjectNormalize;

  const { id, name, sort, isCommercial, spentHours } = item;
</script>

<li class="projectCard card" data-project-id={id}>
  <div class="projectCard__commercial" class:projectCard__commercial--noncommercial={!isCommercial}>
    {#if isCommercial}
      <span>Коммерческий</span>
    {:else}
      <span>Некоммерческий</span>
    {/if}
  </div>
  <div class="projectCard__row">
    <a href={`/projects/projectsTab/${id}`} use:link class="projectCard__link">
      <span class="card__name">{name}</span>
    </a>
    <div class="projectCard__spentHours">
      <span class=" card__hour grayOpenSans" title="Часы(billable часы)">
        <Icon class="material-icons" style="font-size: 14px; margin-right: 2px;color: black"
          >schedule</Icon
        >
        {spentHours.totalHours}ч({spentHours.totalBilledHours}ч)
      </span>
      <span class=" card__hour grayOpenSans" title="часы(billable часы) за неделю">
        <Icon class="material-icons" style="font-size: 14px; margin-right: 2px;color: black"
          >restore</Icon
        >
        {spentHours.lastWeekHours}ч({spentHours.lastWeekBilledHours}ч)
      </span>
    </div>
  </div>
  <!-- <div class="projectCard__row">Планфакт: {item.planfact_id || "не привязан"}</div> -->
  {#if item.projectManager || item.accountManager}
    <Managers projectManager={item.projectManager} accountManager={item.accountManager} />
  {/if}
  <div class="projectCard__milestones grayOpenSans">
    {#if item.milestones}
      {#each item.milestones as { id: milId, name: milName, status, spentHours: milSpentHours } (milId)}
        <div class="projectCard__milestoneItem">
          <div class="projectCard__milestoneItem-meta">
            <div class="projectCard__milestoneItem-name" title={milName}>
              {milName}
            </div>
            <div title="Статус" class="projectCard__milestoneItem-status">
              {status.name}
            </div>
          </div>
          <div class="projectCard__milestoneItem-hours">
            <span class="projectCard__hourItem grayOpenSans" title="Статус вехи">
              <Icon class="material-icons" style="font-size: 11px; margin-right: 2px;color: black"
                >schedule</Icon
              >
              {milSpentHours.totalHours}ч({milSpentHours.totalBilledHours}ч)
            </span>
          </div>
        </div>
      {/each}
    {/if}
  </div>
</li>

<style lang="scss">
  .projectCard {
    margin-bottom: 10px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    &__link {
      margin-bottom: 4px;
      padding-bottom: 2px;
    }

    &__commercial {
      margin-bottom: 12px;

      span {
        padding: 2px 4px;
        background: #017374;
        border-radius: 4px;
        font-size: 11px;
        line-height: 14px;
        color: #ffffff;
      }

      &--noncommercial {
        span {
          background: #666666;
        }
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;
    }

    &__spentHours {
      display: flex;
      align-items: center;
    }

    &__milestoneItem {
      font-size: 11px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;

      &-name {
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 15px;
        line-height: 12px;
      }

      &-status {
        line-height: 12px;
      }

      &-hours {
        flex-shrink: 0;
      }

      &-meta {
        overflow-x: hidden;
      }
    }

    &__link {
      width: 100%;
    }
  }
</style>
