<script lang="ts">
  import Dialog, { Title } from "@smui/dialog";
  import Button from "@smui/button";
  import { invoicesStore } from "stores/invoicesStore";
  import { notifier } from "@beyonk/svelte-notifications";
  import type { IModal } from "interfaces/Common.interface";
  import type { IInvoice } from "interfaces/Invoices.interface";

  export let item: IInvoice | undefined;
  export let modal: IModal | undefined;

  const modalClose = () => modal?.close();

  const { deleteInvoice } = invoicesStore;

  const handleDelete = async () => {
    if (item) {
      const { success, message } = await deleteInvoice(item.id);

      if (!success) {
        notifier.danger(message, 2000);
      } else {
        modalClose();
      }
    }
  };
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={modalClose}>
  <Title id="simple-title">Вы уверены, что хотите удалить счёт?</Title>
  <div class="buttons">
    <Button on:click={handleDelete}>Удалить</Button>&nbsp;
    <Button color="secondary" on:click={modalClose}>Отмена</Button>
  </div>
</Dialog>

<style lang="scss">
  .buttons {
    margin: 20px;
    display: flex;
    justify-content: flex-end;
  }
</style>
