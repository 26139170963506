function hoursNormalize(data) {
    const { last_week_billed_hours, last_week_hours, total_billed_hours, total_hours, } = data;
    return {
        lastWeekBilledHours: last_week_billed_hours,
        lastWeekHours: last_week_hours,
        totalBilledHours: total_billed_hours,
        totalHours: total_hours,
    };
}
export { hoursNormalize };
