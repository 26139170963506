<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import FormField from '@smui/form-field';
  import Checkbox from '@smui/checkbox';

  export let value = false
  export let disabled = false
  export let label = "Подтверждение акта"
  const dispatch = createEventDispatcher();
  const handleChange = (e: Event) =>  dispatch("changeValue", Number((e.target as HTMLInputElement).checked))
</script>

<FormField>
  <Checkbox checked={value}
            {disabled}
            on:change={handleChange}/>
  <span slot="label">{label}</span>
</FormField>



