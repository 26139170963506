<script lang="typescript">
  import { push } from "svelte-spa-router";

  import { Label } from '@smui/button';
  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";

  import { authStore } from "stores/authStore";
  import { usersStore } from "stores/usersStore";

  import UserData from "./UserData.svelte"

  import LogsTable from "../../shared/LogsTable/index.svelte";


  import { Paths } from "constants/path";

  type IProps = { value?: string }
  export let props = {} as IProps

  const { hasPermission } = authStore;

  const {
    error,
    getUser,
    user,
    users,
  } = usersStore


  let isCanEdit = hasPermission([ "users_access", "users_access_money" ]);
  let isUpdatingUser = false;

  const INFO_TAB = "Инфо";
  const CHANGES_TAB = "История изменений";
  let tabs = [ INFO_TAB, CHANGES_TAB ];
  let active;


  $: if (props && isFinite(Number(props.value))) {
    if (hasPermission([ "users_access", "users_access_money" ])) {
      if ($user?.id !== Number(props.value)) {
        isUpdatingUser = true
        getUser(+(props?.value || 0))
          .then(success => {
            if (!success) push(Paths.users)
            else isUpdatingUser = false
          })
      }

    } else push(Paths.main);
  }

</script>

<TabBar bind:active let:tab {tabs}>
  <Tab minWidth {tab}>
    <Label>{tab}</Label>
  </Tab>
</TabBar>
{#if active === INFO_TAB}
  <UserData/>
{:else if active === CHANGES_TAB && props.value}
  <div class="changesTable">
    <LogsTable params={{ type: "users", id: +props.value }}/>
  </div>

{/if}


<style lang="scss">

  .changesTable {
    margin: 30px 0;
  }

</style>
