const authEndpoints = Object.defineProperties({
    root: "auth",
}, {
    accessCode: {
        get: function () {
            return `${this.root}/access-code`;
        },
    },
    login: {
        get: function () {
            return `${this.root}/login`;
        }
    },
    me: {
        get: function () {
            return `${this.root}/me`;
        }
    },
    projectOffice: {
        get: function () {
            return `${this.me}/project-office`;
        }
    },
    projectOfficeStats: {
        get: function () {
            return `${this.me}/project-office/statistics`;
        }
    },
    logout: {
        get: function () {
            return `${this.root}/logout`;
        }
    }
});
export { authEndpoints };
