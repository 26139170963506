<script lang="typescript">
  import { Label } from "@smui/button";

  import Input from "../../shared/Input/index.svelte";
  import Button from "../../shared/Button/index.svelte";
  import { authStore } from "stores/authStore";

  const {
    email,
    message,
    isGetCodeSuccess,
    isFetchGetAccessCode,
    getAccessCode,
  } = authStore;
</script>

<form class="auth__send__email__form">
  <Input
    label="Ваш Email"
    bind:value={$email}
    isShowMessage={$isGetCodeSuccess === false}
    message={$message || ''}
    name="email"
  />
  <Button on:click={getAccessCode} loading={$isFetchGetAccessCode}>
    <Label>Войти</Label>
  </Button>
</form>

<style lang="scss">
  .auth__send__email__form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
