<script lang="ts">
  //@ts-nocheck
  import { onMount } from "svelte";
  import { formatter } from "helpers/formatter";
  import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
  import { Icon } from "@smui/common";
  import Dialog, { Content, Title, Actions } from "@smui/dialog";
  import Button, { Label } from "@smui/button";

  import CoinModal from "../CoinModal/index.svelte";
  import Spinner from "../../../shared/Spinner/index.svelte";
  import CoinTable from "../../../shared/CoinTable/index.svelte";

  import { usersStore } from "stores/usersStore";
  import { authStore } from "stores/authStore";

  import type { ICoinBalance, TTransactionToSaveNormalize } from "interfaces/Coins.interface";
  import type { IModal } from "interfaces/Common.interface";

  export let props: { userId: number };

  const {
    getUsersCoins,
    getUserTransactions,
    deleteUserTransaction,
    saveUserTransaction,
    changeUserTransaction,
    usersCoins,
    isSaveTransaction,
    coinTransactions,
    isFetchTransactions
  } = usersStore;
  const { hasPermission } = authStore;

  let isModalOpen: boolean = false,
    editMode: boolean = false,
    currentUser: ICoinBalance | undefined,
    saveError: string = "",
    currentValues: any,
    transactionIdToDelete: number,
    transactionIdToEdit: number,
    deleteDialog: IModal | undefined;

  $: $usersCoins && findCurrentUser($usersCoins);

  function findCurrentUser(usersCoins: ICoinBalance[]) {
    if (usersCoins) {
      currentUser = usersCoins.find((user) => user.id === props.userId);
    }
  }

  function prepareEditDialog(id: number) {
    transactionIdToEdit = id;
    const transactionToEdit =
      $coinTransactions && $coinTransactions.find((transaction) => transaction.id === id);
    if (transactionToEdit) {
      currentValues = {
        name: transactionToEdit.name,
        amount: Math.abs(transactionToEdit.value),
        operation: transactionToEdit.value < 0 ? -1 : 1,
        productId: transactionToEdit?.product?.id ?? 0
      };
      editMode = true;
      isModalOpen = true;
    }
  }

  async function addHandler(transactionToAdd: TTransactionToSaveNormalize) {
    const { message, success } = editMode
      ? await changeUserTransaction(transactionToAdd, transactionIdToEdit)
      : await saveUserTransaction(transactionToAdd);
    if (!success) {
      saveError = message;
    } else {
      await Promise.all([getUserTransactions({ userId: props.userId }), getUsersCoins()]);
      saveError = "";
      notifier.success(message);
      isModalOpen = false;
    }
  }

  function openDeleteDialog(id: number) {
    transactionIdToDelete = id;
    deleteDialog?.open();
  }

  async function deleteHandler() {
    const { message, success } = await deleteUserTransaction(transactionIdToDelete);
    if (success) {
      await Promise.all([getUserTransactions({ userId: props.userId }), getUsersCoins()]);
      notifier.success(message);
    } else {
      notifier.danger(message);
    }
  }

  onMount(() => {
    if (!$usersCoins) getUsersCoins();

    getUserTransactions({ userId: props.userId });
  });
</script>

<div class="coinsTransactions">
  <NotificationDisplay />
  {#if currentUser}
    <h2 class="coinsTransactions__title">
      {currentUser.name}. Баланс: {formatter.coins(currentUser.coins)} коинов
    </h2>
  {/if}

  {#if hasPermission(["coins_update", "coins_add", "coins_full_access"])}
    <div class="coinsTransactions__btnWrapper">
      <Button variant="unelevated" on:click={() => (isModalOpen = true)}>
        <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
          add
        </Icon>
        <Label>Добавить транзакцию</Label>
      </Button>
    </div>
  {/if}

  {#if $isFetchTransactions}
    <Spinner />
  {:else if $coinTransactions && $coinTransactions.length}
    <CoinTable
      isEditAvailable
      coinTransactions={$coinTransactions}
      on:delete={(e) => openDeleteDialog(e.detail)}
      on:edit={(e) => prepareEditDialog(e.detail)}
    />
  {:else}
    <p>Транзакции не найдены</p>
  {/if}
</div>

<CoinModal
  {isModalOpen}
  {editMode}
  selectedUserId={props.userId}
  externalValues={currentValues}
  isSaveTransaction={$isSaveTransaction}
  usersCoins={$usersCoins ?? []}
  error={saveError}
  on:save={(e) => addHandler(e.detail)}
  on:close={() => {
    isModalOpen = false;
    editMode = false;
  }}
/>

<Dialog bind:this={deleteDialog} aria-labelledby="simple-title" aria-describedby="simple-content">
  <Title id="simple-title">Вы уверены?</Title>
  <Content id="simple-content">Удалить расход?</Content>
  <Actions>
    <Button>
      <Label>No</Label>
    </Button>
    <Button on:click={deleteHandler}>
      <Label>Yes</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  .coinsTransactions {
    &__title {
      margin-bottom: 16px;
    }

    &__btnWrapper {
      margin-bottom: 16px;
    }
  }
</style>
