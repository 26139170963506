const root = {
    companies: "companies",
    companyBySuggestions: "/suggestions/companies"
};
const companiesEndpoints = {
    getCompanies: () => root.companies,
    getCompanyBySuggestions: () => root.companyBySuggestions,
    postCompany: () => root.companies,
    putCompany: (id) => `${root.companies}/${id}`,
    deleteCompany: (id) => `${root.companies}/${id}`
};
export { companiesEndpoints };
