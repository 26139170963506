<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import type { SortableEvent, SortableOptions } from "sortablejs";

  import { draggable } from "../../actions/draggable";
  import CanbanCard from '../CanbanCard/index.svelte'
  import { formatter } from "helpers/formatter";

  import type { ItemsDividedByStatusInterface, SortableItemType } from "interfaces/Sort.interface";

  export let column: ItemsDividedByStatusInterface<SortableItemType>;
  export let itemType : any;
  export let cardThis : any;
  export let customOptionsToDrug : any;
  export let entityIdName : any;

  let items: SortableItemType[];
  //@ts-ignore
  let {name, id, color, revenue} = column;
  const dispatch = createEventDispatcher();
  const onAdd = (e: SortableEvent) => {
    const {to, item, newIndex, oldIndex} = e;
    const statusId = to.dataset.statusId;
    const itemId = item.dataset[entityIdName];
    if (statusId && itemId) {
      dispatch('sort', {
        itemType,
        sortParams: {itemId: Number(itemId), statusId: Number(statusId), oldIndex, newIndex, type: 'adding'}
      })
    }
  }
  const onEnd = (e: SortableEvent) => {
    const {to, from, item, newIndex, oldIndex} = e;
    const statusId = to.dataset.statusId;
    const itemId = item.dataset[entityIdName];
    if (to === from && newIndex !== oldIndex && statusId && itemId) {
      dispatch('sort', {
        itemType,
        sortParams: {statusId: Number(statusId), itemId: Number(itemId), newIndex, oldIndex, type: "sorting"}
      });
    }
  }

  const dragOptions: SortableOptions = {
    ghostClass: 'canbanColumn--ghost',
    onAdd,
    onEnd,
    animation: 150,
    ...customOptionsToDrug
  }
  $: items = column.items;

</script>

<div class="canbanColumn">
  <div class="canbanColumn__header" style={`background-color: ${color}`}>
    <span class="canbanColumn__title">{name}</span>
    {#if revenue}
      <p class="canbanColumn__revenue">
        Выручка: {formatter.money(revenue)}
      </p>
    {/if}
  </div>
  {#if items && Array.isArray(items)}
    <ul class="canbanColumn__list" data-status-id={id} use:draggable={dragOptions}>
      {#each items as item (item.id)}
        <CanbanCard {item} cardThis={cardThis}/>
      {/each}
    </ul>
  {/if}
</div>

<style lang="scss">
  .canbanColumn {
    margin: 10px;
    min-width: 300px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    :global(&--ghost) {
      opacity: .5;
    }

    &__header {
      padding: 7px 0 7px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      line-height: 1.3;
    }

    &__title {
      padding: 0 50px;
      white-space: nowrap;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
    }

    &__revenue {
      font-size: 12px;
    }

    &__list {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 166px);
      padding: 16px 15px;
      background: #F8F8F8;
      border: 1px solid #E4E4E4;
      box-sizing: border-box;
      border-radius: 0 0 6px 6px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 4px;
      }
    }
  }
</style>
