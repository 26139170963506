<script lang="typescript">
  import { onMount, createEventDispatcher } from "svelte";

  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field"

  import Select from "../CustomSelect/index.svelte";

  import { projectsStore } from "stores/projectsStore";
  import { showArchiveStore } from "stores/showArchivedStore";

  import type { IProjectNormalize, IProjectSmall } from "interfaces/Project.interface";
  import type { ILiteMilestoneNormalize, IMilestoneSmall } from "interfaces/Milestone.interface";

  type IDefaultValue = { project: IProjectSmall; milestone: IMilestoneSmall } | undefined;

  //дефолтные значения нужно передавать через prop selectedValue в этом компоненте,
  // так сделано, чтобы в селекторы можно передавать было дефолтные параметры
  export let selectedValue: IDefaultValue = undefined;

  const { getProjects, projects } = projectsStore;
  const { showArchivedFlag } = showArchiveStore;

  const dispatch = createEventDispatcher();

  let selectedProject: IProjectNormalize | undefined = undefined;
  let selectedMilestone: ILiteMilestoneNormalize | undefined = undefined;

  const handleSelectProject = async (e: any) => {
    selectedProject = e.detail;
    selectedMilestone = undefined;
    dispatch("changeValue", null);
  };

  const handleSelectMilestone = (e: any) => {
    selectedMilestone = e.detail;
    dispatch("changeValue", e.detail.id);
  };

  function handleCheckbox(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    showArchiveStore.setShowArchived(checked);
    getProjects()
  }

  let noOptionsMessage = "Не удалось загрузить данные"
  onMount(async () => {
    if (!$projects) {
      try {
        await getProjects();
      } catch (e) {
        noOptionsMessage = e
      }

    }
    selectedProject = $projects?.find((project) => project.id === selectedValue?.project?.id);
    if (selectedProject) {
      selectedMilestone = selectedProject?.milestones?.find(
        (item) => item.id === selectedValue?.milestone?.id
      );
    }
  });

  $: projectList = $projects?.filter((projects) => projects?.milestones?.length);

</script>

<p style="background: #E4E4E4">
  <FormField>
    <Checkbox checked={$showArchivedFlag} on:change={handleCheckbox}/>
    <span slot="label">Показывать вехи архивных проектов </span>
  </FormField>
</p>
<label for="">
  <Select
    {noOptionsMessage}
    items={projectList ?? []}
    isClearable={false}
    placeholder="Проект"
    on:select={handleSelectProject}
    selectedValue={selectedProject}
  />
</label>
<p/>
<label for="">
  <Select
    noOptionsMessage="Выберите проект"
    items={selectedProject?.milestones ?? []}
    isClearable={false}
    placeholder="Веха*"
    on:select={handleSelectMilestone}
    selectedValue={selectedMilestone}
  />
</label>

<style lang="scss">

  p {
    margin-bottom: 10px;
  }
</style>
