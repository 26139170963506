import { writable } from "svelte/store";
const SHOW_ARCHIVED_STORAGE_NAME = "showArchived";
const archivedStorageValue = localStorage.getItem(SHOW_ARCHIVED_STORAGE_NAME);
let showArchivedFlag = writable(Boolean(JSON.parse(archivedStorageValue)));
function setShowArchived(showArchived) {
    localStorage.setItem(SHOW_ARCHIVED_STORAGE_NAME, showArchived.toString());
    showArchivedFlag.set(showArchived);
}
export const showArchiveStore = {
    showArchivedFlag,
    setShowArchived,
};
