import { api } from "api/index";
import { endpoints } from "api/endpoints";
import { editedParamsNormalizeToServer } from "../../normalize/milestonesNormalize";
async function apiPutMilestone(editedParams, milestoneId) {
    var _a;
    try {
        const { data: { success, message }, } = await api({
            method: "PUT",
            url: endpoints.milestones.milestoneById(milestoneId),
            data: editedParamsNormalizeToServer(editedParams),
        });
        return {
            success,
            message,
        };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const { success, message } = errorAxios.response.data;
            return {
                success,
                message: typeof message === "string"
                    ? message
                    : Object.values(message)
                        .map((value) => value.join("\n"))
                        .join("\n"),
            };
        }
    }
}
export default apiPutMilestone;
