<script lang="typescript">
  import { onMount } from "svelte";
  import { link, push } from "svelte-spa-router";

  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import { Label } from "@smui/button";

  import ProductCard from "./ProductCard/index.svelte";
  import Coins from "../../shared/Coins/index.svelte";
  import Spinner from "../../shared/Spinner/index.svelte";
  import CoinTable from "../../shared/CoinTable/index.svelte";

  import { authStore } from "stores/authStore";
  import { productsStore } from "stores/productsStore";
  import { usersStore } from "stores/usersStore";

  interface IParams {
    tab?: string;
  }

  export let params: IParams = {};

  const { userData } = authStore;
  const { isLoading: isLoadingProducts, products, getProducts } = productsStore;
  const { isFetchTransactions, coinTransactions, getPersonalTransactions } = usersStore;

  const tabs = [
    { path: "products", title: "Магазин" },
    { path: "purchases", title: "Мои покупки" }
  ];
  let activeTab;
  $: activeTab = tabs.find((t) => t.path === params?.tab) || tabs[0];
  $: coins = $userData?.coins;

  onMount(() => {
    if (!$products) getProducts();
    //@ts-ignore
    if (!$coinTransactions && $userData) getPersonalTransactions($userData?.id, true);
  });

</script>

<section class="section">
  <a class="link" use:link href="/">
    <p>
      <Coins {coins} />
    </p>
  </a>
  <h1 class="mdc-typography--headline3 days-off__header">Coin Store</h1>
  <article>
    <TabBar active={activeTab} let:tab {tabs}>
      <Tab minWidth on:click={() => push("/store/" + tab.path)} {tab}>
        <Label>{tab.title}</Label>
      </Tab>
    </TabBar>
  </article>
  {#if activeTab.path === "purchases"}
    <div class="tab">
      {#if $isFetchTransactions}
        <div class="_with-air">
          <Spinner />
        </div>
      {:else if $coinTransactions}
        <div class="tab">
          <CoinTable isEditAvailable={false} coinTransactions={$coinTransactions || []} />
        </div>
      {/if}
    </div>
  {:else if $isLoadingProducts}
    <div class="_with-air">
      <Spinner />
    </div>
  {:else}
    <div class="tab">
      <p class="warning">
        Для приобритения чего-то за коины - пишите HR-менеджеру. Все заявки исполняются в течение
        календарного месяца с момента подачи заявки.
      </p>
      {#if $products}
        <div class="products _flex">
          {#each $products as item (item.id)}
            <ProductCard {item} shadow={($userData?.coins || 0) < item?.cost} />
          {/each}
        </div>
      {/if}
    </div>
  {/if}
</section>

<style lang="scss">
  .link {
    &:hover {
      text-decoration: none;
    }
  }

  .warning {
    padding: 20px 0;
    font-weight: bold;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
  }

  .tab {
    padding-top: 10px;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    :global(.card) {
      width: 24%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @media screen and (max-width: 1200px) {
        width: 31.3%;
      }

      @media screen and (max-width: 800px) {
        width: 48%;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

</style>
