import { apiGetRoles } from "../api/methods/roles/apiGetRoles";
import { writable } from "svelte/store";
const roles = writable(null);
const isLoading = writable(false);
const error = writable(null);
const getRoles = async () => {
    isLoading.set(true);
    const result = await apiGetRoles();
    if (result.success)
        roles.set(result.data);
    else
        error.set(result.message);
    isLoading.set(false);
};
export const rolesStore = {
    getRoles,
    roles,
    isLoading,
};
