<script lang="ts">
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text/index";

  export let isShowMessage: boolean;
  export let value: string;
  export let message: string;
  export let label: string;
  export let name: string;
  export let type: string = 'text';
</script>

<div class="input">
  <Textfield
    variant="filled"
    {type}
    bind:value
    {label}
    on:change
    on:input
    input$aria-controls="helper-text-standard-c"
    input$aria-describedby="helper-text-standard-c"
    input$name={name}
  />
  {#if isShowMessage}
    <HelperText id="helper-text-standard-c" persistent class="error">
      {message}
    </HelperText>
  {/if}
</div>

<style lang="scss">
  .input {
    width: 100%;

    :global(.mdc-text-field) {
      width: 100%;
    }

    :global(.error) {
      color: red !important;
    }
  }
</style>
