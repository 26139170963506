<script lang="typescript">
  import Main from "../components/main/index.svelte";
  import Router from "svelte-spa-router";
  import Auth from "../components/auth/index.svelte";

  const routes = {
    "/auth": Auth,
    "/*?": Main
  };
</script>

<Router {routes} />
