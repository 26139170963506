import { __rest } from "tslib";
import axios from "axios";
import { authStore } from "stores/authStore";
import { ResponseStatuses } from "./statuses";
const baseURL = `${process.env.API_URL}/${process.env.API_VERSION}/`;
function api(config) {
    const { headers } = config, otherConfigData = __rest(config, ["headers"]);
    const headersArr = [["Authorization", localStorage.getItem("token")]];
    return axios(Object.assign({ baseURL, headers: Object.assign(Object.assign({}, Object.fromEntries(headersArr.filter(([value]) => Boolean(value)))), headers) }, otherConfigData)).catch((e) => {
        var _a;
        const status = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status;
        if (status === ResponseStatuses.Unauthorized) {
            authStore.clear();
            authStore.clearToken();
        }
        throw e;
    });
}
export { api };
