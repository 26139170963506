<script lang="typescript">
  import { onDestroy } from "svelte";

  import DaysOffTable from "../../../shared/DaysOffTable/index.svelte";
  import Pagination from "../../../shared/Pagination/index.svelte";

  import { daysOffStore } from "stores/daysOffStore";
  import { authStore } from "stores/authStore";
  import { DEFAULT_LENGTH_LIST } from "../../../../constants/constants";

  const { userData } = authStore;

  const { clearDaysOffStore, currentPage, filters, total, getPersonalDaysOff } = daysOffStore;

  const isManageDaysOff = false;

  const handleChangePage = (event) => {
    $currentPage = event.detail.page;
    filters.update((prev) => ({
      ...prev,
      offset: (event.detail.page - 1) * DEFAULT_LENGTH_LIST,
      user_id: $userData?.id
    }));
    //@ts-ignore
    $userData?.id && getPersonalDaysOff($userData.id, $filters);
  };

  $: {
    //@ts-ignore
    if ($userData?.id && isFinite($userData?.id)) {
      //@ts-ignore
      getPersonalDaysOff($userData?.id);
    }
  }
  onDestroy(clearDaysOffStore);
</script>

<section class="days-off">
  <h2 class="mdc-typography--headline4 days-off__header">Мои отсутствия</h2>
  <DaysOffTable {isManageDaysOff} />
  <Pagination
    currentPage={$currentPage}
    total={$total}
    on:change={handleChangePage}
    perPage={DEFAULT_LENGTH_LIST}
  />
</section>

<style lang="scss">
  .days-off {
    padding: 30px 16px 30px 0;

    &__header {
      margin-bottom: 20px;
    }
  }
</style>
