import { stringify } from "query-string";
const projectsEndpoints = {
    root: "projects",
    statuses: "project-statuses",
    all(filters = {}) {
        let queryParams = stringify(filters, { arrayFormat: 'comma' });
        queryParams = queryParams ? `?${queryParams}` : queryParams;
        return `${this.root}${queryParams}`;
    },
    projectById(projectId) {
        return `${this.root}/${projectId}`;
    },
    logs(projectId) {
        return `${this.root}/${projectId}/logs`;
    },
};
export { projectsEndpoints };
