<script lang="typescript">
  import { createEventDispatcher } from "svelte";
  import Button from "@smui/button";

  import Date from "../../Fields/Date.svelte";
  import Text from "../../Fields/Text.svelte";
  import StatusId from "../../Fields/StatusId.svelte";
  import IsApproved from "../../Fields/IsApproved.svelte";
  import InputNumber from "../../Fields/Number.svelte";
  import Scan from "../../Fields/Scan.svelte";
  import MilestoneId from "../../Fields/MilestoneId.svelte";

  import Spinner from "../../Spinner/index.svelte";

  import { authStore } from "stores/authStore";
  import { actsStore } from "stores/actsStore";
  import type { IProjectSmall } from "interfaces/Project.interface";
  import type { IMilestoneSmall } from "interfaces/Milestone.interface";

  const { hasPermission } = authStore;
  const { createAct } = actsStore;

  const dispatch = createEventDispatcher();

  let hasPermissionEdit = hasPermission([ "acts_update", "acts_full_access" ]);
  let hasAccessApprove = hasPermission([ "acts_can_approve", "acts_full_access" ]);
  let hasAccessCommercial = hasPermission([ "acts_read_commercial_info", "acts_full_access" ]);

  type IDefaultValues = {
    milestoneId?: {
      project: IProjectSmall;
      milestone: IMilestoneSmall;
    };
  };
  export let defaultValues: IDefaultValues = {};

  let isCreatingAct;
  let error: string | undefined = "";

  const fieldsConfig = [
    {
      name: "name",
      label: "Имя*",
      component: Text,
      hasAccess: true,
      defaultValues: {}
    },
    {
      name: "date",
      label: "Дата*",
      component: Date,
      hasAccess: true,
      defaultValues: {}
    },
    {
      name: "hours",
      label: "Часы*",
      component: InputNumber,
      hasAccess: true,
      defaultValues: {}
    },
    {
      label: "Веха*",
      name: "milestoneId",
      component: MilestoneId,
      hasAccess: true,
      defaultValues: defaultValues?.milestoneId ? { selectedValue: defaultValues?.milestoneId } : {}
    },
    {
      label: "Статус",
      name: "statusId",
      component: StatusId,
      hasAccess: true,
      defaultValues: {}
    },
    {
      label: "Подтверждение акта",
      name: "isApproved",
      component: IsApproved,
      hasAccess: hasAccessApprove,
      defaultValues: {}
    },
    {
      label: "Сумма",
      name: "price",
      component: InputNumber,
      hasAccess: hasAccessCommercial,
      defaultValues: {}
    },
    {
      label: "Скан",
      name: "scanFile",
      component: Scan,
      hasAccess: hasAccessCommercial,
      defaultValues: {}
    }
  ];

  let values = {
    name: {
      isRequired: true,
      isValid: (value) => !!value,
      value: "",
      hasAccess: true,
    },
    date: {
      isRequired: true,
      isValid: (value) => !!value,
      value: "",
      hasAccess: true,
    },
    hours: {
      isRequired: true,
      isValid: (value) => value > 0,
      value: 0,
      hasAccess: true,
    },
    milestoneId: {
      isRequired: true,
      isValid: (value) => value > 0,
      value: undefined,
      hasAccess: true,
    },
    statusId: {
      isValid: (value) => value > 0,
      isRequired: false,
      value: undefined,
      hasAccess: true,
    },
    isApproved: {
      isValid: (value) => value === 0 || value === 1,
      value: 0,
      hasAccess: hasAccessApprove,
    },
    price: {
      isValid: (value) => (value || value === 0) && value > 0,
      value: 0,
      hasAccess: hasAccessCommercial,

    },
    scanFile: {
      isValid: (value) => !!value,
      value: undefined,
      hasAccess: hasAccessCommercial,
    }
  };
  const checkValid = (values) => {
    let isValid = true;
    Object.keys(values).some((field) => {
      const input = values[field];
      if (input.isRequired && !input.isValid(input.value)) {
        isValid = false;
      }
      return !input.isValid(input.value);
    });
    return isValid;
  };
  const handleChange = (name, value) => {
    values[name].value = value;
  };
  const handleSave = () => {
    if (!checkValid(values)) {
      error = "Введите корректные значения";
      return;
    } else error = "";
    const resultObject = {};
    Object.keys(values).map((key) => {
      const hasValidValue = values[key].value || (key === "isApproved" && values[key].value === 0)
      if (hasValidValue && values[key].hasAccess) resultObject[key] = values[key].value;
    });
    saveAct(resultObject);
  };
  const saveAct = async (data) => {
    isCreatingAct = true;
    const update = await createAct(data);
    if (update.success) dispatch("success");
    else error = update.message;
    isCreatingAct = false;
  };
</script>

<section>
  {#each fieldsConfig as {hasAccess, name, component, label, defaultValues}}
    {#if hasPermissionEdit && hasAccess}
      <div class="field-wrap">
        <!--Сюда должен прилетать компонент, который эмитит событие changeValue с валидным значением -->
        <svelte:component
          this={component}
          {...defaultValues}
          on:changeValue={(event) => handleChange(name, event.detail)}
          {label}
        />
      </div>
    {/if}
  {/each}
  <p>{error}</p>
  <footer>
    <Button on:click={handleSave} variant="raised">Создать акт</Button>
    <div>
      {#if isCreatingAct}
        <Spinner/>
      {/if}
    </div>
  </footer>
</section>

<style lang="scss">
  .field-wrap {
    margin-bottom: 10px;

    :global(label) {
      width: 100%;
    }
  }

  footer {
    display: flex;

    div {
      margin-left: 15px;
    }
  }

  p {
    margin-bottom: 5px;
  }
</style>
