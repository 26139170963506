<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import Textfield from "@smui/textfield";

  export let value = 0;
  export let label = "Количество";
  export let disabled = false

  const dispatch = createEventDispatcher();
  const handleChange = (e: Event) => dispatch("changeValue", Number((e.target as HTMLInputElement).value));
  const handleInput = (e: Event) => dispatch("input", Number((e.target as HTMLInputElement).value));
</script>

<label for="">
  <Textfield
    {value}
    {label}
    {disabled}
    input$min={0}
    input$step={0.01}
    type="number"
    variant="outlined"
    on:input={handleInput}
    on:change={handleChange}
  />
</label>
