<script lang="ts">
  import type { IModal } from "interfaces/Common.interface";
  import InvoicesTable from "../../../Accounting/Invoices/InvoicesTable/index.svelte";
  import InvoiceControls from "../../../Accounting/Invoices/InvoiceControls/index.svelte";
  import InvoiceModal from "../../../Accounting/Invoices/InvoiceModal/index.svelte";
  import { onDestroy, onMount } from "svelte";
  import { invoicesStore } from "stores/invoicesStore";
  import { DEFAULT_LENGTH_LIST } from "constants/constants";
  import { milestonesStore } from "stores/milestonesStore";
  import type { InvoiceFormFieldType } from "interfaces/Invoices.interface";

  const { detailMilestone } = milestonesStore;

  const {
    getInvoiceStatuses,
    getInvoices,
    invoice,
    setDefaultFields,
    currentPositions,
    editId,
    filters,
    emptyPosition
  } = invoicesStore;
  let modal: IModal | undefined;

  const handleModalClose = () => {
    modal?.close();
    $editId = null;
    $invoice = setDefaultFields($invoice);
    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
  };

  const handleModalOpen = () => {
    const milestone: InvoiceFormFieldType | undefined = $invoice.find(
      (item) => item.slug === "milestone"
    );
    if (milestone) {
      milestone.value = { id: $detailMilestone.id, name: $detailMilestone.name };
      milestone.disabled = true;
    }

    $invoice = $invoice;
    modal?.open();
  };

  const handleSubmitInvoice = () => {
    handleModalOpen();
  };

  let isLoading = true;

  onMount(async () => {
    filters.update(() => ({
      limit: DEFAULT_LENGTH_LIST,
      offset: 0,
      search: "",
      status_id: "",
      project_id: undefined,
      milestone_id: $detailMilestone.id
    }));
    await getInvoices();
    await getInvoiceStatuses();
    isLoading = false;
  });

  onDestroy(() => {
    filters.update(() => ({
      limit: DEFAULT_LENGTH_LIST,
      offset: 0,
      search: "",
      status_id: "",
      project_id: undefined,
      milestone_id: undefined
    }));
  });
</script>

<div class="invoices">
  <InvoiceControls isAbleToAdd hasFilters={false} onCreate={handleSubmitInvoice} />
  {#if !isLoading}<InvoiceModal bind:modal onModalClose={handleModalClose} />{/if}
  <div class="invoices__table">
    <InvoicesTable onModalOpen={handleModalOpen} />
  </div>
</div>

<style lang="scss">
  .invoices {
    margin-top: 15px;

    &__table {
      margin-top: 15px;
    }

    :global(.mdc-dialog__surface) {
      min-width: 700px;
      max-height: 80vh;
    }
  }
</style>
