function userCostNormalize(userData) {
    const { last_week_cost, name, total_cost, total_effective_hours, total_ineffective_hours, total_bug_hours, total_hours, } = userData;
    return {
        name,
        lastWeekCost: last_week_cost,
        totalCost: total_cost,
        totalEffectiveHours: total_effective_hours,
        totalIneffectiveHours: total_ineffective_hours,
        totalBugHours: total_bug_hours,
        totalHours: total_hours,
    };
}
function userCostArrayNormalize(userCostArray) {
    return userCostArray.map(userCostNormalize);
}
export { userCostArrayNormalize };
