import { __rest } from "tslib";
import { derived, get, writable } from "svelte/store";
import { apiCreateAct } from "../api/methods/acts/apiCreateAct";
import { apiDeleteAct } from "api/methods/acts/apiDeleteAct";
import { apiGetAct } from "../api/methods/acts/apiGetAct";
import { apiGetActStatuses } from "../api/methods/acts/apiGetActStatuses";
import { apiGetActs } from "../api/methods/acts/apiGetActs";
import { apiUpdateAct } from "../api/methods/acts/apiUpdateAct";
import { createRequestBody } from "../helpers/createRequestBody";
import { getNewSortValue } from "./utils/sortUtils";
import itemsUpdater from "./utils/updater";
const statusColors = {
    1: "#00C4B4",
    2: "#ae6161",
    3: "#528845",
    4: "#4285f4",
    5: "#9ce2ee",
    6: "#797777",
    7: "#0097a7",
};
const isLoadingActs = writable(false);
const isIniting = writable(false);
const acts = writable([]);
const actStatuses = writable([]);
const error = writable(null);
const isUpdatingAct = writable(false);
const isLoadingAct = writable(false);
const act = writable(null);
const statusColumns = derived([acts, actStatuses], ([$acts, $actStatuses]) => {
    if ($acts && $actStatuses && $acts.length && $actStatuses.length) {
        return $actStatuses.map((status, i) => {
            var _a;
            return ({
                id: status.id,
                name: status.name,
                color: (_a = statusColors[i + 1]) !== null && _a !== void 0 ? _a : "#474747",
                items: $acts.filter((act) => act.status.id === status.id).sort((a, b) => a.sort - b.sort)
            });
        });
    }
});
const getActs = async (filters = {}) => {
    isLoadingActs.set(true);
    const { success, data } = await apiGetActs(filters);
    if (data) {
        acts.set(success ? data : []);
    }
    isLoadingActs.set(false);
};
const getStatuses = async () => {
    const { message, success, data } = await apiGetActStatuses();
    if (success)
        actStatuses.set(data || []);
    else
        error.set(message);
};
const initActs = async () => {
    isIniting.set(true);
    await Promise.all([getActs(), getStatuses()]);
    isIniting.set(false);
};
const changeSort = async (sortParams) => {
    var _a;
    const { itemId, statusId, type } = sortParams;
    const editedParams = {};
    if (type === "adding") {
        editedParams.statusId = statusId;
    }
    //@ts-ignore
    const destinationArray = (_a = get(statusColumns).find((stat) => stat.id === statusId)) === null || _a === void 0 ? void 0 : _a.items;
    editedParams.sort = getNewSortValue({
        //@ts-ignore
        destinationArray,
        sortParams
    });
    await saveAct(editedParams, itemId);
};
const createAct = async (data) => {
    const formData = createRequestBody(data);
    const { success, message } = await apiCreateAct(formData);
    if (!success)
        error.set(message);
    return { success, message };
};
const saveAct = async (editedParams, actId) => {
    const { success, message } = await apiUpdateAct(editedParams, actId);
    if (!success) {
        error.set(message);
    }
    const modifiedActs = get(acts).find((pr) => pr.id === actId);
    const { statusId } = editedParams, restChangedParams = __rest(editedParams, ["statusId"]);
    if (statusId) {
        //@ts-ignore
        modifiedActs.status = get(actStatuses).find((st) => st.id === editedParams.statusId);
    }
    acts.update(itemsUpdater(Object.assign(modifiedActs, restChangedParams)));
    error.set(null);
    return { message, success };
};
const deleteAct = async (id) => {
    const { message, success } = await apiDeleteAct(id);
    if (!success) {
        error.set(message);
        return { message, success };
    }
    return { message, success };
};
const updateAct = async (editedParams, actId) => {
    const formData = createRequestBody(editedParams);
    isUpdatingAct.set(true);
    const { success, message } = await apiUpdateAct(formData, actId);
    if (!success)
        error.set(message);
    updateActStore(editedParams);
    updateActsStore(editedParams, actId);
    isUpdatingAct.set(false);
    return { message, success };
};
const getAct = async (actId) => {
    isLoadingAct.set(true);
    const { success, data } = await apiGetAct(actId);
    if (data) {
        if (success)
            act.set(data);
    }
    isLoadingAct.set(false);
    return success;
};
const clear = () => {
    acts.set([]);
    actStatuses.set([]);
    error.set(null);
};
export const actsStore = {
    acts,
    act,
    actStatuses,
    isLoadingAct,
    error,
    isIniting,
    createAct,
    getActs,
    getStatuses,
    initActs,
    clear,
    statusColumns,
    changeSort,
    isLoadingActs,
    getAct,
    isUpdatingAct,
    updateAct,
    deleteAct
};
const updateActStore = (editedParams) => {
    act.update((act) => Object.assign(act, Object.assign({}, editedParams)));
    if (editedParams.hasOwnProperty("statusId"))
        updateStatusOfAct(editedParams.statusId);
};
const updateActsStore = (_editedParams, actId) => {
    //@ts-ignore
    acts.update((acts) => {
        return acts.map((c) => (c.id === actId ? get(act) : c));
    });
};
const updateStatusOfAct = (newStatusId) => {
    act.update((act) => {
        //@ts-ignore
        act.status = getStatusObjectWithId(newStatusId);
        return act;
    });
};
const getStatusObjectWithId = (statusId) => {
    return get(actStatuses).find((status) => status.id === statusId);
};
