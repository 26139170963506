<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import Textfield from '@smui/textfield';
  import { notifier, NotificationDisplay } from '@beyonk/svelte-notifications'

  // Эти переменные можно не прокидывать, если нет потребности в ручном изменении дат
  export let dateFrom = ""
  export let dateTo = ""
  let prevDateFrom = ""
  let prevDateTo = ""

  const dispatch = createEventDispatcher();

  const handleChange = (name: string, event: Event) => {
    // Валидируем даты, если что-то не то - ставим прежние, событие не отправляет
    if (dateFrom && dateTo && new Date(dateFrom) > new Date(dateTo)) {
      dateFrom = prevDateFrom
      dateTo = prevDateTo
      notifier.warning("Выберите корректные даты", 1500)
      return
    }
    const details = {
      name,
      value: (event.target as HTMLInputElement).value
    }
    prevDateFrom = dateFrom
    prevDateTo = dateTo
    dispatch('select', details);
  }

</script>

<NotificationDisplay />
<div class="select-period">
  <Textfield bind:value={dateFrom}
             variant="outlined"
             on:change={(event) => handleChange("dateFrom", event)}
             label="Начало периода"
             type="date"/>
  <Textfield bind:value={dateTo}
             variant="outlined"
             on:change={(event) => handleChange("dateTo", event)}
             label="Конец периода"
             type="date"/>
</div>


<style lang="scss">
  .select-period {
    :global(label.mdc-text-field) {
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }

</style>

