<script lang="ts">
  import type { IInvoice } from "interfaces/Invoices.interface";

  import { createEventDispatcher } from "svelte";

  export let style: string;
  export let isFilled: boolean;
  export let description: string;
  export let invoice: IInvoice;
  export let status_id: number;
  export let isCurrent: boolean;
  export let color: string;

  const dispatch = createEventDispatcher();

  const handleClick = () => {
    if (!isCurrent) {
      dispatch("click", {
        invoiceId: invoice.id,
        status_id,
        description
      });
    }
  };
</script>

<div on:click={handleClick} {style} class={`cell ${isFilled ? color : ''}`}>
  <div class="description">{description}</div>
</div>

<style lang="scss">
  .cell {
    position: relative;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid var(--gray);
    }

    &.gray {
      background: var(--gray);
    }

    &.green {
      background: var(--green);
    }

    &.yellow {
      background: var(--yellow);
    }

    &:hover {
      & > .description {
        display: block;
      }
    }
  }

  .description {
    display: none;
    position: absolute;
    z-index: 999;
    top: 200%;
    max-width: 400px;
    white-space: pre-wrap;
    left: 0;
    background: #fff;
    border-radius: 4px;
    padding: 2px 4px;
  }
</style>
