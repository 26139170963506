import { actsEndpoints as acts } from "./acts";
import { authEndpoints as auth } from "./auth";
import { coinTransactionsEndpoints as coinTransactions } from "./coinTransactions";
import { companiesEndpoints as companies } from "./companies";
import { daysOffEndpoints as daysOff } from "./daysOff";
import { invoicesEndpoints as invoices } from "./invoices";
import { milestonesEndpoints as milestones } from "./milestones";
import { notificationsEndpoints as notifications } from "./notifications";
import { otherCostsEndpoints as otherCosts } from "./otherCosts";
import { payoutsEndpoints as payouts } from "./payouts";
import { productsEndpoints as products } from "./products";
import { projectOfficesEndpoints as projectOffices } from "./projectOffices";
import { projectsEndpoints as projects } from "./projects";
import { rolesEndpoints as roles } from "./roles";
import { usersEndpoints as users } from "./users";
export const endpoints = {
    auth,
    roles,
    notifications,
    projectOffices,
    projects,
    milestones,
    daysOff,
    users,
    acts,
    otherCosts,
    coinTransactions,
    products,
    payouts,
    companies,
    invoices
};
