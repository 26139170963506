import { hoursNormalize } from "./hoursNormalize";
import { liteMilestoneArrayNormalize } from "./milestonesNormalize";
function projectNormalize(data) {
    const { account_manager, code, id, name, project_manager, redmine_id, status, sort, is_commercial, milestones, spent_hours, plan_production_profitability, fact_production_profitability, is_archived, plan_total_profitability, fact_total_profitability, planfact_id } = data;
    return {
        id,
        code,
        name,
        redmineId: redmine_id,
        projectManager: project_manager,
        accountManager: account_manager,
        status,
        sort,
        isCommercial: is_commercial,
        milestones: milestones ? liteMilestoneArrayNormalize(milestones) : null,
        spentHours: hoursNormalize(spent_hours),
        planProductionProfitability: plan_production_profitability,
        factProductionProfitability: fact_production_profitability,
        isArchived: is_archived,
        planTotalProfitability: plan_total_profitability,
        factTotalProfitability: fact_total_profitability,
        planfact_id
    };
}
function projectArrayNormalize(array) {
    return array.map(projectNormalize);
}
function editedParamsNormalizeToServer(changedParams) {
    const normalizeMap = {
        statusId: "status_id",
        sort: "sort",
        projectManagerId: "project_manager_id",
        accountManagerId: "account_manager_id",
        isArchived: "is_archived",
        planfact_id: "planfact_id",
    };
    const serverNormalizedParams = {};
    for (let key in changedParams) {
        if (changedParams.hasOwnProperty(key)) {
            serverNormalizedParams[normalizeMap[key]] = changedParams[key];
        }
    }
    return serverNormalizedParams;
}
export { projectArrayNormalize, projectNormalize, editedParamsNormalizeToServer };
