export const daysOffListNormalize = (data) => {
    return data.map(dayOff => {
        const { id, description, start_date, end_date, user_id, user_name } = dayOff;
        return {
            id,
            description,
            startDate: start_date,
            endDate: end_date,
            userId: user_id,
            userName: user_name,
        };
    });
};
export const daysOffListNormalizeToServer = (data) => {
    return data.map(dayOff => {
        const { id, description, startDate, endDate, userId, userName } = dayOff;
        return {
            id,
            description,
            start_date: startDate,
            end_date: endDate,
            user_id: userId,
            user_name: userName,
        };
    });
};
