import { writable } from "svelte/store";
import { apiGetProjectOffices } from "../api/methods/projectOffices/apiGetProjectOffices";
const projectOffices = writable(null);
const isLoading = writable(false);
const error = writable(null);
async function getProjectsOffices() {
    const { success, data, message } = await apiGetProjectOffices();
    if (success) {
        projectOffices.set(data);
    }
    else {
        throw message;
    }
}
export const projectOfficesStore = {
    getProjectsOffices,
    projectOffices,
    isLoading,
    error
};
