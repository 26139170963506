import { __rest } from "tslib";
import { stringify } from "query-string";
export const coinTransactionsEndpoints = {
    root: "coin-transactions",
    userCoinTransactions(queryObj) {
        const { userId, withProduct } = queryObj, otherProps = __rest(queryObj, ["userId", "withProduct"]);
        const obj = { user_id: userId };
        if (withProduct)
            Object.assign(obj, { with_product: true });
        const queryStr = stringify(Object.assign(otherProps, obj));
        return `${this.root}?${queryStr}`;
    },
    singleTransactionsById(id) {
        return `${this.root}/${id}`;
    }
};
