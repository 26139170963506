<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import Select from 'svelte-select';

  import { rolesStore } from "stores/rolesStore";

  import type { UserRoleType } from "interfaces/User.interface";
import type { IRole } from "interfaces/Roles.interface";

  const dispatch = createEventDispatcher();

  //дефолтные значения нужно передавать через prop defaultValue в этом компоненте,
  // так сделано, чтобы в селекторы можно передавать было дефолтные параметры
  export let defaultValue: UserRoleType[] | undefined = undefined;
  export const label = ""
  export const value = ""

  const handleSelect = async (event: any) => {
    dispatch("changeValue", event.detail);
  };
  const optionIdentifier = "id";
  const getOptionLabel = (option: any) => option.name;
  const getSelectionLabel = (option: any) => option.name;
  const { roles, getRoles } = rolesStore

  //@ts-ignore
  $: selectedValue = $roles?.filter?.((role: IRole) => defaultValue?.includes(role.name))

  onMount(() => {
    if (!$roles) getRoles()
  })

</script>


<label for="">
  <p>Роли:</p>
  <Select
    {optionIdentifier}
    {getSelectionLabel}
    {getOptionLabel}
    items={$roles ?? []}
    on:select={handleSelect}
    selectedValue={selectedValue}
    isMulti={true}/>
</label>

