<!--
Селект, значения передаются в форме [{name: string, id: number}]
-->
<script lang="typescript">
import type { IOption } from "interfaces/Common.interface";

  import Select from "svelte-select";

  export let items: IOption[] = [];
  export let isDisabled = false;
  export let placeholder = "Выберите";
  export let selectedValue: IOption | undefined = undefined;
  export let isClearable = true;
  export let isWaiting = false;
  export let listAutoWidth = false;
  export let hasError = false;
  export let noOptionsMessage:string = '';

  const optionIdentifier = "id";
  const getOptionLabel = (option: any) => option.name;
  const getSelectionLabel = (option: any) => option.name;
  let hasFocus = false,
    listOpen = false;
  const handleClickPlaceholder = () => {
    setTimeout(() => {
      if (!(hasFocus || selectedValue)) {
        listOpen = true;
        hasFocus = true;
      }
    });
  };

</script>

<div class={`selector-wrap ${hasError ? "_has-error" : ""}`}>
  <div
    class={`placeholder ${hasFocus || selectedValue ? "_has-focus" : ""} ${
      selectedValue && !hasFocus ? "_has-value" : ""
    }`}
    on:click={handleClickPlaceholder}
  >
    {placeholder}
  </div>
  <Select
    {items}
    {isDisabled}
    placeholder={placeholder || noOptionsMessage}
    {selectedValue}
    {optionIdentifier}
    {getSelectionLabel}
    {getOptionLabel}
    {isWaiting}
    {isClearable}
    {listAutoWidth}
    bind:isFocused={hasFocus}
    bind:listOpen
    containerClasses="custom-select"
    on:select
    on:clear
  />
</div>

<style lang="scss">
  .selector-wrap {
    position: relative;
    padding-top: 6px;

    &._has-error {
      & .placeholder {
        color: var(--red);
      }

      :global(.custom-select.selectContainer) {
        border-color: var(--red);
      }
    }

    :global(.custom-select.selectContainer) {
      cursor: pointer;
      border-radius: 4px;
      height: 56px;
      border-color: rgba(0, 0, 0, 0.4);
    }

    :global(.custom-select.selectContainer.focused) {
      // override lib styles
      border-color: var(--violet) !important;
    }
    :global(.custom-select.selectContainer .indicator) {
      transform: rotateX(0);
      transition: 0.4s all;
    }
    :global(.custom-select.selectContainer.focused .indicator) {
      transform: rotateX(180deg);
    }

    .placeholder {
      position: absolute;
      top: 25px;
      left: 16px;
      z-index: 1;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.15px;
      transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      transform: scale(1);
      background-color: white;

      &._has-focus {
        top: -2px;
        padding: 0 5px;
        color: var(--purple);
        left: 12px;
        transform-origin: left;
        transform: scale(0.75);
      }
      &._has-value {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

</style>
