<script lang="typescript">
  import Button from '@smui/button';
  import { Icon } from '@smui/common';

  export let disabled = true
  export let variant = "default"

</script>


<Button class={$$props.class + " btn"}
        {disabled}
        on:click
        {variant}>
  {#if !disabled && variant !== "default"}
    <Icon class="material-icons" title="редактировать">mode_edit</Icon>
  {/if}
  <slot/>
  {#if !disabled && variant === "default"}
    <Icon class="material-icons icon__btn _ml8" title="редактировать">mode_edit</Icon>
  {/if}
</Button>


<style lang="scss">
  :global(i.icon__btn._ml8) {
    margin-left: 8px !important;
  }
</style>
