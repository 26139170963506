<script lang="ts">
  import Dialog, { Title } from "@smui/dialog";
  import Button from "@smui/button";
  import { notifier } from "@beyonk/svelte-notifications";
  import Spinner from "../../../../shared/Spinner/index.svelte";
  import type { IModal } from "interfaces/Common.interface";
  import type { IInvoice } from "interfaces/Invoices.interface";
  import { apiPostInvoiceFile } from "api/methods/invoices/apiPostInvoiceFile";

  export let item: IInvoice | undefined;
  export let modal: IModal | undefined;

  const modalClose = () => modal?.close();
  let isLoading = false;

  const handleDownload = async (is_signed: boolean) => {
    if (item) {
      isLoading = true;
      const { message, success } = await apiPostInvoiceFile(item.id, is_signed);

      if (success) {
        window.open(message.file_link);
        modalClose();
      } else {
        notifier.danger(message, 2000);
      }

      isLoading = false;
    }
  };
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={modalClose}>
  <Title id="simple-title">Скачивание счёта</Title>
  <div class="buttons">
    {#if isLoading}
      <Spinner />
    {:else}
      <div class="controls">
        <Button variant="outlined" on:click={() => handleDownload(true)}>С печатью</Button>&nbsp;
        <Button on:click={() => handleDownload(false)}>Без печати</Button>
      </div>
    {/if}

    <Button color="secondary" on:click={modalClose}>Отмена</Button>
  </div>
</Dialog>

<style lang="scss">
  .buttons {
    margin: 20px;
    display: flex;
    justify-content: space-between;
  }
</style>
