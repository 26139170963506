import { api } from "api/index";
import { endpoints } from "api/endpoints";
import { transactionToServerUpdate } from "api/normalize/coinsNormalize";
async function apiPutUserTransaction(transactionToEdit, transactionId) {
    var _a;
    try {
        const { data: { success, message } } = await api({
            url: endpoints.coinTransactions.singleTransactionsById(transactionId),
            method: "PUT",
            data: transactionToServerUpdate(transactionToEdit)
        });
        return { success, message };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const { success, message } = errorAxios.response.data;
            return {
                success,
                message
            };
        }
    }
}
export default apiPutUserTransaction;
