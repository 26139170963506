<script lang="typescript">
  import { createEventDispatcher } from "svelte";

  import Textfield from "@smui/textfield";

  export let label = "Скан";

  const dispatch = createEventDispatcher();

  const handleFiles = (e: Event) => {
    //@ts-ignore
    dispatch("changeValue", e.target.files[0]);
  };

  let valueTypeFiles = [];
</script>

<label for="">
  <Textfield
    bind:files={valueTypeFiles}
    {label}
    input$accept=".pdf, .png, .bmp, .jpeg"
    type="file"
    variant="outlined"
    on:change={handleFiles}
  />
</label>

<style lang="scss">
  label {
    padding-top: 5px;

    :global(.mdc-text-field .mdc-floating-label) {
      top: 25%;
      transition: 0.4s;
      background: white;
      transform: translateY(-106%) scale(.75) !important;
    }

  }
</style>
