const sortDelta = 1000;
const getNewSortValue = ({ destinationArray, sortParams }) => {
    var _a, _b;
    const { oldIndex, newIndex, type } = sortParams;
    const direction = newIndex < oldIndex ? "up" : "down";
    const previousSortValue = (_a = destinationArray[direction === "up" || type === "adding" ? newIndex - 1 : newIndex]) === null || _a === void 0 ? void 0 : _a.sort;
    const nextSortValue = (_b = destinationArray[direction === "up" || type === "adding" ? newIndex : newIndex + 1]) === null || _b === void 0 ? void 0 : _b.sort;
    let newSortValue;
    if (!previousSortValue && !nextSortValue) {
        newSortValue = sortDelta * 5;
    }
    else if (!nextSortValue) {
        newSortValue = previousSortValue + sortDelta;
    }
    else if (!previousSortValue) {
        newSortValue = nextSortValue - sortDelta;
    }
    else {
        newSortValue = Math.abs(Math.floor((nextSortValue - previousSortValue) / 2) + previousSortValue);
    }
    return newSortValue;
};
export { getNewSortValue };
