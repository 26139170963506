import { stringify } from "query-string";
export const usersEndpoints = {
    root: "users",
    daysOff: "days-off",
    coins: "coins",
    coinsTransaction: "coin-transactions",
    detailed: "detailed",
    log: "logs",
    all(filters = {}) {
        let queryParams = stringify(filters, { arrayFormat: 'comma' });
        queryParams = queryParams ? `?${queryParams}` : queryParams;
        return `${this.root}${queryParams}`;
    },
    allDetailed(filters) {
        let queryParams = stringify(filters, { arrayFormat: 'comma' });
        queryParams = queryParams ? `?${queryParams}` : queryParams;
        return `${this.root}/${this.detailed}${queryParams}`;
    },
    one(id) {
        return `${this.root}/${id}`;
    },
    logs(id) {
        return `${this.root}/${id}/${this.log}`;
    },
    ofOneDaysOff(userId, filters = {}) {
        let str = stringify(filters);
        str = str ? `?${str}` : str;
        return `${this.root}/${userId}/${this.daysOff}${str}`;
    },
    personalCoins(id, withProduct) {
        let str = withProduct ? `?with_product=true` : "";
        return `${this.root}/${id}/${this.coinsTransaction}${str}`;
    },
    usersCoins() {
        return `${this.root}/${this.coins}`;
    }
};
