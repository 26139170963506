<script lang="ts">
  import { Label } from "@smui/button";

  import Input from "../../shared/Input/index.svelte";
  import Button from "../../shared/Button/index.svelte";

  import { authStore } from "stores/authStore";

  const { code, message, errorMessage, isFetchSendCode, sendCode } = authStore;
</script>

<span class="auth__send__code__message">{$message}</span>
<form class="auth__send__code__form">
  <Input
    label="Укажите код из письма"
    bind:value={$code}
    name="access-code"
    isShowMessage={$errorMessage !== null}
    message={$errorMessage || ''}
  />
  <Button on:click={sendCode} loading={$isFetchSendCode}>
    <Label>Войти</Label>
  </Button>
</form>

<style lang="scss">
  .auth__send__code__message {
    margin-bottom: 10px;
  }

  .auth__send__code__form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
