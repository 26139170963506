const root = {
    salaryRegistries: "salary-registries",
    PaymentInformation: (id) => `/salary-registries/${id}/position`,
    ApprovePayment: (id) => `/salary-registries/${id}/position`,
    GetMessages: (id) => `/salary-registries/${id}/position/messages`,
    AddMessage: (id) => `/salary-registries/${id}/position/messages`,
    ReadMessages: (id) => `/salary-registries/${id}/position/messages`
};
const payoutsEndpoints = {
    getSalaryRegistries: () => root.salaryRegistries,
    getPaymentInformation: (id) => root.PaymentInformation(id),
    putApprovePayment: (id) => root.ApprovePayment(id),
    putMessagesRead: (id) => root.ReadMessages(id),
    getMessages: (id) => root.GetMessages(id),
    postMessage: (id) => root.AddMessage(id)
};
export { payoutsEndpoints };
