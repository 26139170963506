import { prepareFilters } from "api/normalize/otherCostNormalize";
import { stringify } from "query-string";
export const otherCostsEndpoints = {
    root: "other-costs",
    all(filters) {
        let query = stringify(prepareFilters(filters), { skipNull: true });
        query = query ? `?${query}` : query;
        return `${this.root}${query}`;
    },
    singleCost(id) {
        return `${this.root}/${id}`;
    }
};
