<script lang="ts">
  import Textfield from "@smui/textfield";
  import Button, { Label } from "@smui/button";
  import { authStore } from "stores/authStore";
  const { userData } = authStore;

  let value = "";

  const send = () => {
    const text = value;
    if (!text) return;

    value = "";
    //@ts-ignore
    $userData?.id && addMessage($userData.id, id, text);
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Enter") send();
  };

  export let id: number;
  export let addMessage: (userId: number, id: number, text: string) => void;
</script>

<div class="typing-block">
  <Textfield
    style="width: 100%;"
    on:keydown={handleKeydown}
    bind:value
    label="Напишите сообщение"
  />
  <div class="message-block-button">
    <Button color="secondary" variant="unelevated" on:click={send}>
      <Label>Отправить</Label>
    </Button>
  </div>
</div>

<style lang="scss">
  .typing-block {
    display: flex;
    align-items: flex-end;

    .message-block-button {
      margin-left: 10px;
    }
  }
</style>
