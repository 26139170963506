<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import Select from "svelte-select";

  import { usersStore } from "stores/usersStore";

  import { prepareListToSelect } from "../../../helpers/prepareListToSelect";

  export let placeholder = "Выберите сотрудника";

  const dispatch = createEventDispatcher();
  const { users, getUsers, error } = usersStore;

  function handleSelect(event: CustomEvent) {
    dispatch("select", {
      id: event.detail.value,
      name: event.detail.label
    });
  }

  $: items = prepareListToSelect($users);

  onMount(() => {
    if (!$users.length) getUsers();
  });
</script>

{#if !$error}
  <Select {items} containerClasses="select-user" {placeholder} on:select={handleSelect} on:clear />
{/if}

<style lang="scss">
  :global {
    .select-user {
      // shadow style of lib
      width: 100%;
      &.focused {
        border-color: rgb(98, 0, 238) !important;
      }
    }
  }
</style>
