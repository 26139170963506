<script lang="typescript">
  import { createEventDispatcher, onMount } from "svelte";

  import Select from "../../CustomSelect/index.svelte";

  import { projectOfficesStore } from "stores/projectOfficesStore";

  type IValue = { id: string; name: string };
  export const selectedValue: IValue | undefined = undefined;
  export const label = "";
  export let placeholder = "Выберите проектный офис";
  export let value = undefined;

  const { getProjectsOffices, projectOffices } = projectOfficesStore;

  const dispatch = createEventDispatcher();

  const handleSelect = (e: any) => {
    dispatch("changeValue", e.detail);
  };
  const handleClear = () => {
    dispatch("changeValue", null)
  };
  onMount(() => {
    if (!$projectOffices) getProjectsOffices()
  })

</script>

<label for="">
  <Select
    items={$projectOffices ?? []}
    {placeholder}
    selectedValue={value}
    on:select={handleSelect}
    on:clear={handleClear}
  />
</label>
