import { api } from "api/index";
import { endpoints } from "api/endpoints";
async function apiLogin({ email, code, }) {
    var _a, _b;
    try {
        const { data: { success, message }, } = await api({
            method: "POST",
            url: endpoints.auth.login,
            data: {
                email,
                access_code: code,
            },
        });
        return {
            success,
            accessToken: message.access_token,
            tokenType: message.token_type,
            expiresIn: message.expires_in,
        };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const { success, message } = (_b = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _b === void 0 ? void 0 : _b.data;
            return {
                success,
                errorMessage: typeof message === "string"
                    ? message
                    : Object.values(message)
                        .map((value) => value.join("\n"))
                        .join("\n"),
            };
        }
    }
}
export default apiLogin;
