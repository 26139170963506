import { api } from "api";
import { endpoints } from "api/endpoints";
import { actsServerToNormalize } from "../../normalize/actsNormalize";
export async function apiGetActs(filters) {
    var _a;
    try {
        const { data: { success, message }, } = await api({
            url: endpoints.acts.all(filters),
            method: "GET",
        });
        return { success, data: actsServerToNormalize(message) };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if (errorAxios.response.data) {
                const { success, message } = errorAxios.response.data;
                return {
                    success,
                    message,
                };
            }
        }
    }
}
