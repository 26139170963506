import { api } from "api";
import { endpoints } from "api/endpoints";
export async function apiDeleteAct(actId) {
    try {
        const { data } = await api({
            url: endpoints.acts.one(actId),
            method: "DELETE"
        });
        return data;
    }
    catch (error) {
        const errorAxios = error;
        if (errorAxios.response.data) {
            const { success, message } = errorAxios.response.data;
            return {
                success,
                message
            };
        }
    }
}
