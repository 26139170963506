import { api } from "api";
import { endpoints } from "api/endpoints";
export async function apiUpdateNotifications(notifications_ids) {
    var _a;
    try {
        const { data: { success, message }, } = await api({
            url: endpoints.notifications.all(),
            data: {
                notifications_ids
            },
            method: "PUT",
        });
        return {
            success, data: message
        };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if (errorAxios.response.data) {
                const { success, message } = errorAxios.response.data;
                return {
                    success,
                    message,
                };
            }
        }
    }
}
