<script lang="ts">
  import { modalPanelStore } from "stores/modalPanelStore";

  import MainRouter from "./routes/MainRouter.svelte";
  const { isOpen } = modalPanelStore;
</script>

<svelte:head>
  <title>Yo! Digital Spectr</title>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700"
    rel="stylesheet"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700"
    rel="stylesheet"
  />
  <link href="images/fav/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
  <link href="images/fav/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
  <link href="images/fav/favicon.ico" rel="shortcut icon" type="image/x-icon" />
  {#if $isOpen}
      <style>
         body {
            overflow: hidden;
         }
      </style>
   {/if}
</svelte:head>

<MainRouter />

<style global lang="scss">
  :root {
    --purple: rgb(98, 0, 238);
    --violet: #6200ee;
    --green: #049949;
    --red: #e32628;
    --yellow: #d3f400;
    --fullwidth: 100%;
    --gray: #bbbbbb;
    --gray-text: rgba(0, 0, 0, 0.6);
    --green-brown: rgb(74, 65, 42);
  }

  * {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  body {
    position: relative;
    margin: 0;
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #000000;

    &:hover {
      text-decoration: underline;
    }
  }

  .grayOpenSans {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #80878f;
  }

  .section {
    padding: 30px 16px 30px 0;
  }

  .mdc-data-table {
    width: 100%;
  }

  .spinner-container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    min-height: 200px;
  }
</style>
