import { writable } from "svelte/store";
import apiGetOtherCosts from "api/methods/otherCosts/apiGetOtherCosts";
import apiPostOtherCost from "api/methods/otherCosts/apiPostOtherCost";
import apiDeleteOtherCost from "api/methods/otherCosts/apiDeleteOtherCost";
import apiPutOtherCost from "api/methods/otherCosts/apiPutOtherCost";
const otherCosts = writable(null);
const errorMessage = writable(null);
const isFetching = writable(false);
async function getOtherCosts(filter) {
    isFetching.set(true);
    const { success, data, message } = await apiGetOtherCosts(filter);
    if (!success) {
        errorMessage.set(message);
        isFetching.set(false);
        return;
    }
    otherCosts.set(data);
    isFetching.set(false);
}
async function postNewCost(otherCost) {
    return await apiPostOtherCost(otherCost);
}
async function putCost(otherCost) {
    return await apiPutOtherCost(otherCost);
}
async function deleteCost(id) {
    return await apiDeleteOtherCost(id);
}
function clearCosts() {
    otherCosts.set(null);
}
export { otherCosts, isFetching, errorMessage, postNewCost, getOtherCosts, clearCosts, deleteCost, putCost };
