<script lang="ts">
  import { Icon } from "@smui/common";
  import type { HoursNormalizeInterface } from "interfaces/Hours.interface";
  import type { BillingType } from "interfaces/Milestone.interface";

  export let spentHours: HoursNormalizeInterface,
    totalProjectHours: number,
    billingType: BillingType,
    maxBillingHours: number;

  let {
    totalHours: totalMilestoneHours,
    totalBilledHours,
    lastWeekHours,
    lastWeekBilledHours
  } = spentHours;

  let color = "#80878F";
  $: {
    if (billingType === "Fixed Price" && totalMilestoneHours > maxBillingHours) {
      if (totalProjectHours - lastWeekHours <= maxBillingHours) color = "yellow";
      else color = "red";
    }
  }
</script>

<span class="grayOpenSans card__hour"  title="Часы(billable часы)">
  <Icon class="material-icons" style="color: black;font-size: 14px; margin-right: 2px">schedule</Icon>
  {totalMilestoneHours}ч({totalBilledHours}ч)
</span>
<span class="grayOpenSans card__hour" style={`color: ${color}`}  title="Часы(billable часы) за неделю">
  <Icon class="material-icons" style={`color: black; font-size: 14px; margin-right: 2px`}
    >restore</Icon
  >
  {lastWeekHours}ч({lastWeekBilledHours}ч)
</span>

