import apiGetLogs from "../api/methods/logs/apiGetLogs";
import { writable } from "svelte/store";
const logs = writable(null);
const errorMessage = writable(null);
async function getProjectLogs(projectId) {
    const { data, success, message } = await apiGetLogs({
        id: projectId,
        type: "projects",
    });
    if (!success) {
        errorMessage.set(message);
        return;
    }
    logs.set(data);
}
async function getMilestoneLogs(milestoneId) {
    const { data, success, message } = await apiGetLogs({
        id: milestoneId,
        type: "milestones",
    });
    if (!success) {
        errorMessage.set(message);
        return;
    }
    logs.set(data);
}
async function getActsLogs(actId) {
    const { data, success, message } = await apiGetLogs({
        id: actId,
        type: "acts",
    });
    if (!success) {
        errorMessage.set(message);
        return;
    }
    logs.set(data);
}
async function getUserLogs(userId) {
    const { data, success, message } = await apiGetLogs({
        id: userId,
        type: "users",
    });
    if (!success) {
        errorMessage.set(message);
        return;
    }
    logs.set(data);
}
export { logs, getProjectLogs, getMilestoneLogs, getActsLogs, getUserLogs };
