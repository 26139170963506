<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import Button from '@smui/button';

  import { DEFAULT_LENGTH_LIST } from "constants/constants";

  const dispatch = createEventDispatcher();

  export let currentPage = 1
  export let total = 0
  export let disabled = false
  export let perPage = DEFAULT_LENGTH_LIST
  export let maxCountPages = 10

  const handleCurrentPage = (idx: number) => {
    if (currentPage !== idx) {
      dispatch("change", {
        page: idx
      })
    }
  }
  const boundLeft = 2
  const boundRight = 2
  const countVisiblePage = 1 + boundRight + boundLeft
  $: pages = Math.ceil(total / perPage)

</script>

{#if pages > 1 }
  <div class="pagination">
    {#if pages > maxCountPages }
      <Button class="page"
              on:click={() => handleCurrentPage(1)}
              variant="{1 === currentPage  ? 'raised' : 'outlined'}"
              color="secondary"
              {disabled}>
        {1}
      </Button>
      {#if currentPage >= countVisiblePage }
        <Button disabled>...</Button>
      {/if  }
      {#each Array(countVisiblePage).fill(countVisiblePage) as _, i}
        {#if (currentPage - boundLeft + i) > 1 && (currentPage - boundRight + i) < pages }
          <Button class="page"
                  on:click={() => handleCurrentPage(currentPage - boundLeft + i)}
                  variant="{i === 2  ? 'raised' : 'outlined'}"
                  color="secondary"
                  {disabled}>
            {currentPage - boundLeft + i}
          </Button>
        {/if}
      {/each}
      {#if currentPage <= pages - (boundLeft + boundRight) }
        <Button disabled>...</Button>
      {/if  }
      <Button class="page"
              on:click={() => handleCurrentPage(pages)}
              variant="{pages === currentPage   ? 'raised' : 'outlined'}"
              color="secondary"
              {disabled}>
        {pages}
      </Button>
    {:else}
      {#each Array(pages).fill(undefined) as _, i}
        <Button class="page"
                on:click={() => handleCurrentPage(i + 1)}
                variant="{i + 1 === currentPage  ? 'raised' : 'outlined'}"
                color="secondary"
                {disabled}>
          {i + 1}
        </Button>
      {/each}
    {/if}
  </div>
{/if}

<style lang="scss">
  .pagination {
    padding: 15px 0;
    flex-wrap: wrap;
    display: flex;

    :global(button) {
      margin-right: 5px;
    }
  }

</style>
