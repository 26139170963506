<script lang="typescript">
  import Textfield from "@smui/textfield";
  import Button from "@smui/button";
  import CustomSelect from "../../../../shared/CustomSelect/index.svelte";
  import { companiesStore } from "stores/companiesStore";
  import { onMount } from "svelte";
  import { invoicesStore } from "stores/invoicesStore";
  import { toSelectItems } from "helpers/helpers";
  import { milestonesStore } from "stores/milestonesStore";
  import { ERORRS } from "constants/constants";
  import type { ISetInvoice } from "interfaces/Invoices.interface";
  import { format } from "date-fns";
import type { IOption } from "interfaces/Common.interface";

  export let onModalClose: () => void;

  const { companies, getCompanies } = companiesStore;
  const {
    statuses,
    positionUnits,
    getPositionUnits,
    createInvoice,
    editInvoice,
    invoice,
    editId,
    currentPositions,
    emptyPosition
  } = invoicesStore;
  const { milestones, getLiteMilestones } = milestonesStore;

  onMount(async () => {
    await Promise.all([getCompanies(), getPositionUnits(), getLiteMilestones()]);

    $invoice.forEach((item) => {
      if (item.slug === "milestone") item.items = toSelectItems($milestones, "name", "id", true);
      if (item.slug === "company") {
        const filtered = $companies.companies?.filter((item) => !item.is_our_company);
        item.items = toSelectItems(filtered || [], "name", "id", true);
      }
      if (item.slug === "self_company") {
        const filtered = $companies.companies?.filter((item) => item.is_our_company);
        const items = toSelectItems(filtered || [], "name", "id", true);
        item.items = items;
        item.value = items[0] || null;
        item.defaultValue = items[0] || null;
      }
      if (item.slug === "status") {
        const items = toSelectItems($statuses, "name", "id", true);
        item.items = items;
        item.value = items.find((item) => item.name === "Черновик") || items[0] || null;
        item.defaultValue = items.find((item) => item.name === "Черновик") || items[0] || null;
      }
      if (item.slug === "date") {
        item.value = format(new Date(), "yyyy-MM-dd");
        item.defaultValue = format(new Date(), "yyyy-MM-dd");
      }
      if (item.slug === "payment_date") {
        const day = new Date();
        day.setDate(day.getDate() + 7);
        item.value = format(day, "yyyy-MM-dd");
        item.defaultValue = format(day, "yyyy-MM-dd");
      }
    });

    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
    $invoice = $invoice;
  });
  let unitItems: IOption[];
  //@ts-ignore
  $: unitItems = toSelectItems($positionUnits, "name", "id", true);

  const handleInputChange = (e: Event, index: number, positionKey?: string) => {
    const value = (e.target as HTMLInputElement).value.trim();

    if (positionKey) {
      if (value) {
        $currentPositions[index].errors[positionKey] = "";
      } else {
        $currentPositions[index].errors[positionKey] = ERORRS.REQUIRED;
      }

      $currentPositions[index][positionKey] = value;
      return;
    }

    if (!value) {
      $invoice[index].error = ERORRS.REQUIRED;
    } else {
      $invoice[index].error = "";
    }
    $invoice[index].value = value;
  };

  const handleSelectChange = (e: any, index: number, positionKey?: string) => {
    if (positionKey) {
      $currentPositions[index][positionKey] = "";
      $currentPositions[index][positionKey] = e.detail;
      return;
    }

    $invoice[index].error = "";
    $invoice[index].value = e.detail;
  };

  const handleClear = (index: number, positionKey?: string) => {
    if (positionKey) {
      $currentPositions[index][positionKey] = ERORRS.REQUIRED;
      $currentPositions[index][positionKey] = null;
      return;
    }

    $invoice[index].value = null;
    $invoice[index].error = ERORRS.REQUIRED;
  };

  const addPosition = () => {
    $currentPositions = [...$currentPositions, JSON.parse(JSON.stringify($emptyPosition))];
  };

  const deletePosition = (i: number) => {
    $currentPositions.splice(i, 1);
    $currentPositions = $currentPositions;
  };

  const onSubmit = async () => {
    let isValid = true;

    $invoice.forEach((item) => {
      if (!item.value && !item.disabled && item.required) {
        isValid = false;
        item.error = ERORRS.REQUIRED;
      } else {
        item.error = "";
      }
    });

    $currentPositions.forEach((item) => {
      const errors = item.errors;
      Object.keys(errors).forEach((key) => {
        if (key === "quantity" || key === "price") {
          if (typeof item[key] === "object") {
            isValid = false;
            errors[key] = ERORRS.REQUIRED;
          } else {
            errors[key] = "";
          }
        }
        if (key === 'unit' || key === 'name') {
          if (!item[key]) {
            isValid = false;
            errors[key] = ERORRS.REQUIRED;
          } else {
            errors[key] = "";
          }
        }
      });
    });

    $invoice = $invoice;
    $currentPositions = $currentPositions;

    if (!isValid) return;

    //@ts-ignore
    const data: ISetInvoice = $invoice.reduce((acc, field) => {
      if (
        !field.group &&
        !field.isGroup &&
        field.name !== "planfact_payment_id" &&
        field.name !== "total_sum"
      ) {
        acc[field.name] = field.type === "select" ? field.value?.id || null : field.value;
      }

      return acc;
    }, {});

    //@ts-ignore
    data.positions = $currentPositions.map((item) => {
      return {
        name: item.name,
        unit_id: item.unit?.id,
        price: item.price,
        quantity: item.quantity
      };
    });

    //@ts-ignore
    const result = $editId ? await editInvoice(data, $editId) : await createInvoice(data);

    if (result.success) {
      onModalClose();
    } else {
      Object.entries(result.message).forEach(([key, value]) => {
        const item = $invoice.find((item) => item.name === key);
        if (item) {
          item.error = value[0];
        }
      });

      $invoice = $invoice;
    }
  };
</script>

<form on:submit|preventDefault={() => {}} class="form">
  {#each $invoice as { label, type, value, items, disabled, error, hidden, slug }, i}
    {#if !hidden || (hidden && $editId)}
      <div class="item">
        {#if type !== "select"}
          <Textfield
            invalid={error}
            required
            {disabled}
            variant="outlined"
            {type}
            {label}
            bind:value
            on:input={(e) => handleInputChange(e, i)}
          />
          {#if error}<div class="red">{error}</div>{/if}
        {:else}
          <CustomSelect
            hasError={!!error}
            isWaiting={!items}
            {items}
            isDisabled={disabled}
            placeholder={label}
            selectedValue={value}
            on:select={(e) => handleSelectChange(e, i)}
            on:clear={() => handleClear(i)}
          />
          {#if error}<div class="red">{error}</div>{/if}
        {/if}
      </div>
    {/if}
  {/each}
  <div class="item">
    <h3>Позиции</h3>
  </div>
  {#each $currentPositions as { name, quantity, price, unit, errors }, i}
    <fieldset class="fieldset">
      <legend>{`Позиция ${i + 1}`}</legend>
      <div class="fieldset__item">
        <Textfield
          invalid={errors.name}
          variant="outlined"
          type="text"
          label="Название"
          bind:value={name}
          on:input={(e) => handleInputChange(e, i, "name")}
        />
      </div>
      <div class="fieldset__item">
        <Textfield
          invalid={errors.quantity}
          variant="outlined"
          type="number"
          input$step='any'
          label="Количество"
          input$novalidate=""
          bind:value={quantity}
          on:input={(e) => handleInputChange(e, i, "quantity")}
        />
      </div>
      <div class="fieldset__item">
        <CustomSelect
          hasError={!!errors.unit}
          items={unitItems}
          isWaiting={!unitItems}
          placeholder="Единица измерения"
          selectedValue={unit}
          on:select={(e) => handleSelectChange(e, i, "unit")}
          on:clear={() => handleClear(i, "unit")}
        />
      </div>
      <div class="fieldset__item">
        <Textfield
          invalid={errors.price}
          variant="outlined"
          type="number"
          input$step='any'
          label="Цена"
          input$novalidate=""
          bind:value={price}
          on:input={(e) => handleInputChange(e, i, "price")}
        />
      </div>
      <div class="delete">
        <Button disabled={$currentPositions.length === 1} on:click={() => deletePosition(i)}>Удалить</Button>
      </div>
    </fieldset>
  {/each}
  <div class="item">
    <div class="right">
      <Button on:click={addPosition}>Добавить позицию</Button>
    </div>
  </div>
  <div class="buttons">
    <Button on:click={onSubmit}>Сохранить</Button>
  </div>
</form>

<style lang="scss">
  .fieldset {
    margin: 10px;
    padding: 10px 30px;

    &__item {
      padding: 10px 0;
    }
  }
  .item {
    padding: 10px 40px;
  }

  .delete {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .buttons {
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 35px;
  }

  .red {
    color: var(--red);
  }
</style>
