<script lang="ts">
  import { link } from "svelte-spa-router";
  import { Icon } from "@smui/common";

  import type { ILiteMilestoneNormalize } from "interfaces/Milestone.interface";
  import MilestoneSpentHours from "../../../shared/MilestoneSpentHours/index.svelte";
  import Managers from "../../../shared/Managers/index.svelte";

  import { formatter } from "helpers/formatter";

  export let item: ILiteMilestoneNormalize;
  const classesMap = {
    success: "_success",
    passably: "_passably",
    bad: "_bad",
    veryBad: "_very-bad",
    undefined: "_undefined",
  }
  const getModificator = (value: null | number): string => {
    if (value || value === 0) {
      if (value > 45) return classesMap.success
      else if (value > 33) return classesMap.passably
      else if (value > 0) return classesMap.bad
      else return classesMap.veryBad
    } else {
      return classesMap.undefined
    }
  }

  const {
    id,
    name,
    spentHours,
    maxBillingHours,
    project: {
      isCommercial,
      name: projectName,
      projectManager,
      accountManager,
      spentHours: { totalHours: totalProjectHours }
    }
  } = item;
</script>

<li class="milestoneCard card" data-project-id={id}>
  <div class={`indicator ${getModificator(item.factProductionProfitability)}`}></div>
  <div class="milestoneCard__projectName">
    <Icon
      class="material-icons"
      style={`color: ${isCommercial ? "green" : "gray"};font-size: 16px; margin-right: 2px`}
    >
      monetization_on
    </Icon>
    <span class="milestoneCard__project" title={projectName}>
      {projectName}
    </span>
  </div>
  <div class="milestoneCard__row">
    <a href={`/projects/milestonesTab/${id}`} use:link>
      <span class="card__name">
         {name}
      </span>
    </a>
  </div>
  <div class="milestoneCard__row">
    <span>
      {item.billingType}
    </span>
    {#if item.billingType === "Fixed Price"}
      (max {maxBillingHours}h)
    {/if}
    {#if item.isGuarantee}
      -&nbsp;<span style="color: red">free :(</span>
    {/if}
  </div>
  <div class="milestoneCard__row">
    <div class="milestoneCard__spentHours">
      <MilestoneSpentHours
        billingType={item.billingType}
        {maxBillingHours}
        {spentHours}
        {totalProjectHours}
      />
    </div>
  </div>
  <div class="milestoneCard__row">
    <div class="milestoneCard__spentHours">
      {#if item.revenue}
        <span class="grayOpenSans card__hour revenue" title="Выручка">
          <strong>
             <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
               <path
                 d="M0.25007 9.24984H2.00011V10.75C2.00011 10.823 2.02346 10.8828 2.07045 10.9295C2.1173 10.9765 2.17721 11 2.25018 11H3.5548C3.62251 11 3.6811 10.9765 3.73059 10.9295C3.78008 10.8828 3.80474 10.823 3.80474 10.75V9.24984H7.75006C7.82288 9.24984 7.8828 9.22664 7.92977 9.17963C7.97678 9.13266 8.00011 9.07287 8.00011 8.99988V7.99984C8.00011 7.92728 7.97678 7.8671 7.92977 7.82039C7.88293 7.77338 7.82288 7.74988 7.75006 7.74988H3.80461V6.82796H6.46087C7.50249 6.82796 8.35263 6.51057 9.01167 5.87489C9.67045 5.23987 10 4.41958 10 3.41398C10 2.40905 9.67045 1.58874 9.01167 0.953074C8.35275 0.318036 7.50247 0 6.46087 0H2.25005C2.17706 0 2.11728 0.0234824 2.07032 0.07021C2.02346 0.117196 1.99998 0.177358 1.99998 0.24994V5.16412H0.25007C0.1771 5.16412 0.117305 5.18881 0.0703396 5.23828C0.0235041 5.28776 0 5.34635 0 5.41404V6.57798C0 6.65097 0.0233531 6.71077 0.0703396 6.75773C0.117326 6.80474 0.17697 6.82794 0.25007 6.82794H2.00011V7.74986H0.25007C0.1771 7.74986 0.117305 7.77336 0.0703396 7.82037C0.0235041 7.86707 0 7.92713 0 7.99982V8.99986C0 9.07285 0.0233531 9.13264 0.0703396 9.17961C0.117305 9.22664 0.177078 9.24984 0.25007 9.24984ZM3.80461 1.66423H6.30463C6.85665 1.66423 7.30189 1.82563 7.64054 2.14854C7.97902 2.47147 8.14833 2.89349 8.14833 3.41411C8.14833 3.93499 7.97902 4.35714 7.64054 4.67968C7.30189 5.00287 6.85663 5.16427 6.30463 5.16427H3.80461V1.66423Z"
                 fill="black"/>
             </svg>
          </strong>
          {formatter.decimal(item.revenue)}
        </span>
      {/if}
      {#if item.totalProfitability}
            <span class="grayOpenSans card__hour" title="Рентабельность">
          <strong>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path
                d="M1.78125 5.625C2.06488 5.625 2.32159 5.5112 2.51129 5.32846L3.55197 5.84875C3.54675 5.89114 3.53906 5.9328 3.53906 5.97656C3.53906 6.55811 4.01221 7.03125 4.59375 7.03125C5.17529 7.03125 5.64844 6.55811 5.64844 5.97656C5.64844 5.81424 5.60852 5.66226 5.54269 5.52475L6.95444 4.11301C7.09195 4.17883 7.24393 4.21875 7.40625 4.21875C7.98779 4.21875 8.46094 3.74561 8.46094 3.16406C8.46094 3.05457 8.43942 2.95102 8.40829 2.85168L9.63492 1.93195C9.80228 2.04373 10.0029 2.10938 10.2188 2.10938C10.8003 2.10938 11.2734 1.63623 11.2734 1.05469C11.2734 0.473145 10.8003 0 10.2188 0C9.63721 0 9.16406 0.473145 9.16406 1.05469C9.16406 1.16418 9.18558 1.26773 9.21671 1.36707L7.99008 2.2868C7.82272 2.17502 7.62213 2.10938 7.40625 2.10938C6.82471 2.10938 6.35156 2.58252 6.35156 3.16406C6.35156 3.32639 6.39148 3.47836 6.45731 3.61588L5.04556 5.02762C4.90805 4.96179 4.75607 4.92188 4.59375 4.92188C4.31012 4.92188 4.05341 5.03568 3.86371 5.21841L2.82303 4.69812C2.82825 4.65573 2.83594 4.61407 2.83594 4.57031C2.83594 3.98877 2.36279 3.51562 1.78125 3.51562C1.19971 3.51562 0.726562 3.98877 0.726562 4.57031C0.726562 5.15186 1.19971 5.625 1.78125 5.625Z"
                fill="black"/>
              <path
                d="M11.6484 11.2969H11.2734V3.86719C11.2734 3.67291 11.1161 3.51562 10.9219 3.51562H9.51562C9.32135 3.51562 9.16406 3.67291 9.16406 3.86719V11.2969H8.46094V5.97656C8.46094 5.78229 8.30365 5.625 8.10938 5.625H6.70312C6.50885 5.625 6.35156 5.78229 6.35156 5.97656V11.2969H5.64844V8.78906C5.64844 8.59479 5.49115 8.4375 5.29688 8.4375H3.89062C3.69635 8.4375 3.53906 8.59479 3.53906 8.78906V11.2969H2.83594V7.38281C2.83594 7.18854 2.67865 7.03125 2.48438 7.03125H1.07812C0.88385 7.03125 0.726562 7.18854 0.726562 7.38281V11.2969H0.351562C0.157288 11.2969 0 11.4542 0 11.6484C0 11.8427 0.157288 12 0.351562 12H11.6484C11.8427 12 12 11.8427 12 11.6484C12 11.4542 11.8427 11.2969 11.6484 11.2969Z"
                fill="black"/>
            </svg>
          </strong>
              {formatter.percent(item.totalProfitability)}
        </span>
      {/if}

    </div>
  </div>
  {#if accountManager || projectManager}
    <Managers {accountManager} {projectManager}/>
  {/if}
</li>

<style lang="scss">
  .milestoneCard {
    position: relative;

    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__projectName {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &__project {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
    }

    &__spentHours {
      display: flex;
      align-items: center;
    }

  }

  .card__hour {
    font-size: 13px;

    strong {
      margin-right: 6px;
    }

    &.revenue {
      margin-right: 12px !important;
    }
  }

  ._success {
    background: var(--green);
  }

  ._passably {
    background: var(--yellow);
  }

  ._bad {
    background: var(--red);
  }

  ._very-bad {
    background: var(--green-brown);
  }

  ._undefined {
    background: var(--gray);
  }

</style>
