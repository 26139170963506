import { __rest } from "tslib";
function userNormalize(data) {
    const { billing_type, is_in_project_office } = data, otherProps = __rest(data, ["billing_type", "is_in_project_office"]);
    return Object.assign(otherProps, {
        billingType: billing_type,
        isInProjectOffice: is_in_project_office
    });
}
export function userDetailNormalize(data) {
    const { billing_type, day_hours, redmine_id, hiring_date, termination_date, project_office } = data, otherProps = __rest(data, ["billing_type", "day_hours", "redmine_id", "hiring_date", "termination_date", "project_office"]);
    return Object.assign(otherProps, {
        dayHours: day_hours,
        projectOffice: project_office,
        redmineId: redmine_id,
        hiringDate: hiring_date,
        terminationDate: termination_date,
    }, billing_type ? { billingType: billing_type } : {});
}
export function usersDetailNormalize(data) {
    return data.map(userDetailNormalize);
}
export function updateUserFilterToServer(data) {
    const { redmineId, dayHours, billingType, projectOfficeId } = data, otherProps = __rest(data, ["redmineId", "dayHours", "billingType", "projectOfficeId"]);
    return Object.assign(otherProps, redmineId ? {} : { redmine_id: redmineId }, dayHours ? {} : { day_hours: dayHours }, billingType ? {} : { billing_type: billingType }, projectOfficeId ? {} : { project_office_id: projectOfficeId });
}
export default userNormalize;
