<script lang="typescript">
  import { createEventDispatcher } from "svelte";

  import Select from "../CustomSelect/index.svelte";

  import { actsStore } from "stores/actsStore";

  type IValue = { id: string; name: string };
  export let selectedValue: IValue | undefined = undefined;
  export let placeholder = "Выберите статус";

  const { actStatuses } = actsStore;

  const dispatch = createEventDispatcher();

  const handleSelect = (e: any) => {
    dispatch("changeValue", e.detail.id);
    selectedValue = e.detail
  };
</script>

<label for="">
  <Select
    items={$actStatuses ?? []}
    {placeholder}
    {selectedValue}
    isClearable={false}
    on:select={handleSelect}
  />
</label>
