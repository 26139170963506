import { format } from "date-fns";
let ru = new Intl.NumberFormat("ru", {
    style: "currency",
    currency: "RUB"
});
let co = new Intl.NumberFormat("ru");
let decimal = new Intl.NumberFormat("ru");
let percent = {
    format(count) {
        return decimal.format(count) + " %";
    }
};
export const formatter = {
    money: (money) => ru.format(money),
    coins: (coins) => coins ? co.format(coins) : '-',
    percent: (count) => percent.format(count),
    decimal: (count) => decimal.format(count),
    date: (date) => {
        if (date !== null && !Number.isNaN(Number(new Date(date)))) {
            return format(new Date(date), 'dd.MM.uuuu');
        }
        return 'не задано';
    },
};
