<script lang="ts">
  import { onDestroy } from "svelte";
  import { link } from "svelte-spa-router";
  import { formatter } from "helpers/formatter";
  import format from "date-fns/format";
  import parseISO from "date-fns/parseISO";
  import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
  import Button, { Label } from "@smui/button";
  import { Icon } from "@smui/common";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Spinner from "../../../../shared/Spinner/index.svelte";
  import ActAdd from "../../../../shared/Act/Add/index.svelte";
  import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
  import { actsStore } from "stores/actsStore";
  import { authStore } from "stores/authStore";
  import type { IDetailMilestoneNormalize } from "interfaces/Milestone.interface";

  export let milestone: IDetailMilestoneNormalize;

  const { clear, getActs, acts, deleteAct, getStatuses } = actsStore;
  const { hasPermission } = authStore;

  async function getMilestoneActs() {
    return getActs({ milestoneId: milestone.id });
  }

  function deleteClickHandler(id: number) {
    deleteDialog.open();
    actToDeleteId = id;
  }

  async function openActAddDialogHandler() {
    addDialog.open();
    await getStatuses();
  }

  async function successAddHandler() {
    addDialog.close();
    await getActs({ milestoneId: milestone.id });
  }

  let isUpdatingAct = false;

  async function deleteHandler() {
    isUpdatingAct = true;
    const { message, success } = await deleteAct(actToDeleteId);
    if (!success) {
      notifier.danger(message);
      isUpdatingAct = false;
      return;
    }
    notifier.success(message);
    await getActs({ milestoneId: milestone.id });
    isUpdatingAct = false;
  }

  const hasAddPermission = hasPermission(["acts_add", "acts_full_access"]);
  const hasDeletePermission = hasPermission(["acts_delete", "acts_full_access"]);
  let deleteDialog: any, addDialog: any, actToDeleteId: number;

  let selectedMilestone = {
    milestoneId: {
      milestone: { id: milestone.id, name: milestone.name },
      project: { id: milestone.project.id, name: milestone.project.name }
    }
  };

  onDestroy(clear);
</script>

<NotificationDisplay />
<div class="milestoneActs">
  <div
    class:milestoneActs__addBtnWrapper--none={!hasAddPermission}
    class="milestoneActs__addBtnWrapper"
  >
    <Button variant="unelevated" on:click={openActAddDialogHandler}>
      <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
        add
      </Icon>
      <Label>Добавить акт</Label>
    </Button>
  </div>

  {#await getMilestoneActs()}
    <div class="milestoneActs__spinnerWrapper">
      <Spinner />
    </div>
  {:then}
    {#if isUpdatingAct}
      <div class="milestoneActs__spinnerWrapper">
        <Spinner />
      </div>
    {:else if $acts.length}
      <DataTable table$aria-label="UserCost">
        <Head>
          <Row>
            <Cell>Название</Cell>
            <Cell>Дата</Cell>
            <Cell>Часы</Cell>
            <Cell>Цена</Cell>
            <Cell>Статус</Cell>
            <Cell>Подтверждение</Cell>
            <Cell />
            {#if hasDeletePermission}
              <Cell />
            {/if}
          </Row>
        </Head>
        <Body>
          {#each $acts as { id, name, date, hours, price, status, isApproved } (id)}
            <Row>
              <Cell>
                <a href={`/acts/${id}`} use:link>
                  {name}
                </a>
              </Cell>
              <Cell>{format(parseISO(date), "dd.MM.yyyy")}</Cell>
              <Cell>{hours}</Cell>
              <Cell>
                {price && price !== 0 ? formatter.money(price) : "-"}
              </Cell>
              <Cell>{status.name}</Cell>
              <Cell>{isApproved ? "Да" : "Нет"}</Cell>
              <Cell>
                <a href={`/acts/${id}`} class="milestoneActs__editLink" use:link>Редактировать</a>
              </Cell>
              {#if hasDeletePermission}
                <Cell>
                  <p class="milestoneActs__delete" on:click={() => deleteClickHandler(id)}>
                    Удалить
                  </p>
                </Cell>
              {/if}
            </Row>
          {/each}
        </Body>
      </DataTable>
    {:else}
      <p class="milestoneActs__empty">Акты отсутствуют</p>
    {/if}
  {/await}
</div>

<Dialog bind:this={deleteDialog} aria-labelledby="simple-title" aria-describedby="simple-content">
  <Title id="simple-title">Вы уверены?</Title>
  <Content id="simple-content">Удалить акт?</Content>
  <Actions>
    <Button>
      <Label>No</Label>
    </Button>
    <Button on:click={() => deleteHandler()}>
      <Label>Yes</Label>
    </Button>
  </Actions>
</Dialog>

<Dialog bind:this={addDialog}>
  <Title>Добавить акт</Title>
  <Content>
    <ActAdd on:success={successAddHandler} defaultValues={selectedMilestone} />
  </Content>
</Dialog>

<style lang="scss">
  .milestoneActs {
    &__addBtnWrapper {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-end;

      &--none {
        display: none;
      }
    }

    &__spinnerWrapper {
      display: flex;
      justify-content: center;
    }

    &__editLink {
      color: #6200ee;
    }

    &__delete {
      color: red;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__empty {
      text-align: center;
    }
  }
</style>
