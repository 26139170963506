import { __rest } from "tslib";
import { derived, get, writable } from "svelte/store";
import apiGetDetailProject from "../api/methods/projects/apiGetDetailProject";
import apiGetProjects from "../api/methods/projects/apiGetProjects";
import apiGetStatuses from "../api/methods/apiGetStatuses";
import apiPutProject from "../api/methods/projects/apiPutProject";
import { getNewSortValue } from "./utils/sortUtils";
import itemsUpdater from "./utils/updater";
import { showArchiveStore } from "./showArchivedStore";
import { usersStore } from "./usersStore";
const statusColors = {
    1: "#00C4B4",
    2: "#ae6161",
    3: "#528845",
    4: "#4285f4",
    5: "#9ce2ee",
    6: "#797777",
    7: "#0097a7",
};
const closedStatusId = 4;
const projects = writable(null);
const detailProject = writable(null);
const statuses = writable(null);
const errorMessage = writable(null);
const statusColumns = derived([projects, statuses], ([$projects, $statuses]) => {
    if ($projects && $statuses) {
        return $statuses.map((status, i) => {
            var _a;
            return ({
                id: status.id,
                name: status.name,
                color: (_a = statusColors[i + 1]) !== null && _a !== void 0 ? _a : "#474747",
                items: $projects
                    .filter((project) => project.status.id === status.id)
                    .map((project) => {
                    var _a;
                    return (Object.assign(Object.assign({}, project), { milestones: (_a = project === null || project === void 0 ? void 0 : project.milestones) === null || _a === void 0 ? void 0 : _a.filter((mil) => mil.status.id !== closedStatusId) }));
                })
                    .sort((a, b) => a.sort - b.sort),
            });
        });
    }
});
async function initProjects() {
    try {
        await Promise.all([
            getProjects(),
            getProjectsStatuses(),
        ]);
    }
    catch (error) {
        errorMessage.set(error);
    }
}
async function getProjects(filters = {}) {
    const { success, data, message } = await apiGetProjects(get(showArchiveStore.showArchivedFlag), filters);
    if (success) {
        data && projects.set(data);
    }
    else {
        projects.set([]);
        throw message;
    }
}
async function getDetailProject(projectId) {
    const { success, data, message } = await apiGetDetailProject(projectId);
    if (success) {
        data && detailProject.set(data);
    }
    else {
        message && errorMessage.set(message);
    }
}
async function getProjectsStatuses() {
    const { success, data, message } = await apiGetStatuses("projects");
    if (success) {
        data && statuses.set(data.map((status) => (Object.assign(Object.assign({}, status), { color: statusColors[status.id] }))));
    }
    else {
        throw message;
    }
}
async function changeProjectSort(sortParams) {
    const { itemId, statusId, type } = sortParams;
    const editedParams = {};
    if (type === "adding") {
        editedParams.statusId = statusId;
    }
    //@ts-ignore
    const destinationArray = get(statusColumns).find((stat) => stat.id === statusId).items;
    editedParams.sort = getNewSortValue({
        destinationArray,
        sortParams,
    });
    await saveProject(editedParams, itemId);
}
async function saveProject(changedParams, projectId) {
    const { success, message } = await apiPutProject(changedParams, projectId);
    if (!success) {
        errorMessage.set(message);
        return { message, success };
    }
    //@ts-ignore
    const modifiedProject = get(projects).find((pr) => pr.id === projectId);
    const { statusId, projectManagerId, accountManagerId } = changedParams, restChangedParams = __rest(changedParams, ["statusId", "projectManagerId", "accountManagerId"]);
    if (statusId) {
        //@ts-ignore
        modifiedProject.status = get(statuses).find((st) => st.id === changedParams.statusId);
    }
    if (projectManagerId) {
        //@ts-ignore
        modifiedProject.projectManager = get(usersStore.users).find((user) => user.id === projectManagerId);
    }
    if (accountManagerId) {
        //@ts-ignore
        modifiedProject.accountManager = get(usersStore.users).find((user) => user.id === accountManagerId);
    }
    projects.update(
    //@ts-ignore
    itemsUpdater(Object.assign({}, modifiedProject, restChangedParams)));
    errorMessage.set(null);
    return { message, success };
}
export const projectsStore = {
    projects,
    errorMessage,
    statusColumns,
    detailProject,
    getDetailProject,
    saveProject,
    initProjects,
    getProjects,
    changeProjectSort,
};
