import { api } from "api/index";
import { endpoints } from "api/endpoints";
import userNormalize from "api/normalize/userNormalize";
async function apiMe() {
    try {
        const { data: { success, message }, } = await api({
            url: endpoints.auth.me,
            method: "POST",
        });
        return {
            success,
            data: userNormalize(message),
        };
    }
    catch (error) {
        const errorAxios = error;
        if (errorAxios.response.data) {
            const { success, message } = errorAxios.response.data;
            return {
                success,
                errorMessage: message,
            };
        }
    }
}
export default apiMe;
