<script lang="typescript">
  import { onDestroy, onMount } from "svelte";

  import Button from "@smui/button";
  import Dialog, { Title, Content } from "@smui/dialog";
  import Textfield from "@smui/textfield";
  import { notifier , NotificationDisplay } from "@beyonk/svelte-notifications";
  import { push } from "svelte-spa-router";

  import DaysOffTable from "../../shared/DaysOffTable/index.svelte";
  import Spinner from "../../shared/Spinner/index.svelte";
  import Pagination from "../../shared/Pagination/index.svelte";
  import SelectUser from "../../shared/SelectUser/index.svelte";
  import DayOffFilters from "./DayOffFilters/index.svelte";
  import DayOffButtons from "./DayOffButtons/index.svelte";

  import { daysOffStore } from "stores/daysOffStore";
  import { authStore } from "stores/authStore";

  import { Paths } from "constants/path";
  import { DEFAULT_LENGTH_LIST } from "constants/constants";

  const {
    clearDaysOffStore,
    setDayOffInModal,
    getDaysOff,
    startDate,
    endDate,
    description,
    userId,
    isUpdatingDayOff,
    dayOff,
    updateFilters,
    currentPage,
    total
  } = daysOffStore;

  const { hasPermission } = authStore;

  const isManageDaysOff = true;
  let dayOffModal;
  let activeDayOff;
  let modalMode: "add" | "edit" = "add";

  const clickHandler = (data) => {
    setDayOffInModal(data);
    dayOffModal.open();
  };
  const handleClickOpen = (): void => {
    modalMode = "add";
    clickHandler({});
  };

  const handleClickEdit = (event): void => {
    modalMode = "edit";
    activeDayOff = event.detail;
    clickHandler(event.detail);
  };

  const handleSave = () => {
    const data = {
      start_date: $startDate,
      end_date: $endDate,
      description: $description,
      user_id: $userId
    };
    const fn = () => (isAddMode ? dayOff.create(data) : dayOff.update(activeDayOff.id, data));
    fn().then((data) => {
      dayOffModal.close();
      notifier.info(data);
    });
  };
  const handleSelect = (event) => ($userId = event.detail.id);

  const handleChangePage = (event) => {
    $currentPage = event.detail.page;
    updateFilters({
      offset: (event.detail.page - 1) * DEFAULT_LENGTH_LIST
    });
  };

  const validateDayOff = (userId, startDate, endDate) => {
    return (
      !isNaN(parseInt(userId)) && endDate && startDate && new Date(endDate) >= new Date(startDate)
    );
  };

  $: isAddMode = modalMode === "add";
  $: isValidData = validateDayOff($userId, $startDate, $endDate);

  onMount(() => {
    if (hasPermission("daysoff_access")) getDaysOff();
    else push(Paths.main);
  });

  onDestroy(clearDaysOffStore);
</script>

<section class="days-off section">
  <h1 class="mdc-typography--headline3 days-off__header">Управление отсутствиями</h1>
  <DayOffFilters />
  <DayOffButtons on:click={handleClickOpen} />
  <DaysOffTable {isManageDaysOff} on:edit={handleClickEdit} />
  <Pagination currentPage={$currentPage} total={$total} on:change={handleChangePage} />
  <Dialog bind:this={dayOffModal}>
    {#if $isUpdatingDayOff}
      <Content>
        <Spinner />
      </Content>
    {:else}
      <Title id="simple-title">{isAddMode ? "Добавить" : "Редактировать"} отсутствие</Title>
      <div class="days-off__fields-wrap">
        <Textfield class="days-off__field" bind:value={$description} label="Описание" textarea />
        <Textfield
          class="days-off__field"
          bind:value={$startDate}
          label="Начало отсутствия"
          type="date"
        />
        <Textfield
          class="days-off__field"
          bind:value={$endDate}
          label="Окончание отсутствия"
          type="date"
        />
        {#if isAddMode}
          <SelectUser on:select={handleSelect} />
        {/if}
      </div>
    {/if}
    <footer class="days-off__buttons">
      <Button on:click={handleSave} disabled={!isValidData || $isUpdatingDayOff}>Сохранить</Button>
    </footer>
  </Dialog>

</section>
<NotificationDisplay />

<style lang="scss">
  .days-off {
    &__header {
      margin-bottom: 20px;
    }
    &__buttons {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 15px;
    }

    &__fields-wrap {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    :global(&__field) {
      margin-bottom: 20px;

      &.mdc-select {
        & > :global(div) {
          width: 100%;
        }
      }
    }

    :global(.mdc-dialog__surface) {
      overflow-y: inherit;
    }
  }
</style>
