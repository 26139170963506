<script lang="typescript">
  import { createEventDispatcher } from "svelte";

  import Textfield from "@smui/textfield";

  export let value = "";
  export let label = "Имя проекта";
  export let placeholder = "Введите значение";
  export let variant = "outlined";
  export let invalid = false;
  export let style = "";

  const dispatch = createEventDispatcher();

</script>

<div>
  <Textfield
    {style}
    {value}
    {label}
    {variant}
    {invalid}
    input$placeholder={placeholder}
    type="text"
    on:input={(e) => dispatch("input", e.target.value)}
    on:change={(e) => dispatch("changeValue", e.target.value)}
  />
</div>

<style>
  div {
    padding-top: 5px;
  }

</style>
