import { api } from "api/index";
import { endpoints } from "api/endpoints";
export const apiPutCompany = async (company, id) => {
    var _a;
    try {
        const { data: { success, message } } = await api({
            url: endpoints.companies.putCompany(id),
            method: "PUT",
            data: Object.assign({}, company)
        });
        return { success, message };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const { success, message } = errorAxios.response.data;
            return {
                success,
                message
            };
        }
    }
};
