import { writable } from "svelte/store";
import { apiGetAdjustment } from "api/methods/adjustments/apiGetAdjusmtents";
const adjustments = writable(null);
const isLoading = writable(false);
const error = writable(null);
const getAdjustemnts = async () => {
    isLoading.set(true);
    const result = await apiGetAdjustment();
    if (result.success) {
        adjustments.set(result.data);
    }
    else
        error.set(result.message);
    isLoading.set(false);
};
export const adjustmentsStore = {
    getAdjustemnts,
    adjustments,
    isLoading,
};
