import { api } from "api";
import { endpoints } from "api/endpoints";
export async function apiCreateAct(data) {
    var _a;
    try {
        const { data: { success, message }, } = await api({
            url: endpoints.acts.all(),
            method: "POST",
            data
        });
        return { success, message };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if (errorAxios.response.data) {
                const { success, message } = errorAxios.response.data;
                if (typeof message === "string") {
                    return {
                        success,
                        message,
                    };
                }
                else if (typeof message === "object" && Object.keys(message).length) {
                    const data = Object.values(message)[0][0];
                    return {
                        success,
                        message: data,
                    };
                }
                else {
                    return {
                        success,
                        message: "Произошла ошибка",
                    };
                }
            }
        }
    }
}
