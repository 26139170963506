<script lang="ts">
  import { Icon } from '@smui/common';
  import { createEventDispatcher } from "svelte";
  import type { UserInterface } from "interfaces/User.interface";

  export let accountManager: UserInterface,
    projectManager: UserInterface,
    isEditable: boolean = false;

  const dispatch = createEventDispatcher();
  const clickHandler = (manager: any) => {
    if (isEditable) dispatch('edit', manager)
  }
</script>

<div class="managers">
  <span class="managers__item grayOpenSans"
        title="Проджект менеджер"
        class:managers__item--editable={isEditable}
        on:click={() => clickHandler('projectManager')}
  >
    <Icon class="material-icons" style="font-size: 14px; margin-right: 2px; color: #F4C550">star_rate</Icon>
    <span>
      {projectManager?.name || "Не назначен"}
    </span>
  </span>
  <span class="managers__item grayOpenSans"
        title="Аккаунт менеджер"
        class:managers__item--editable={isEditable}
        on:click={() => clickHandler('accountManager')}
  >
    <Icon class="material-icons" style="font-size: 14px; margin-right: 2px">content_copy</Icon>
    <span>
      {accountManager?.name || "Не назначен"}
    </span>
  </span>
</div>

<style lang="scss">
  .managers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding-bottom: 5px;

    &__item {
      display: flex;
      margin: 0 10px;
      align-items: center;
      font-size: 11px;
      line-height: 18px;

      &--editable {
        span {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
