import { get, writable } from "svelte/store";
import { DEFAULT_LENGTH_LIST } from "constants/constants";
import { apiGetNotifications } from "../api/methods/notifications/apiGetNotifications";
import { apiUpdateNotifications } from "../api/methods/notifications/apiUpdateNotification";
const defaultFilters = { limit: DEFAULT_LENGTH_LIST, offset: 0 };
const filters = writable(defaultFilters);
const notifications = writable([]);
const isLoadNotifications = writable(false);
const notificationsCount = writable(0);
const notificationsUnreadCount = writable(0);
const getNotifications = async () => {
    isLoadNotifications.set(true);
    const { success, data } = await apiGetNotifications(get(filters));
    if (success) {
        notificationsCount.set(data.total);
        notificationsUnreadCount.set(data.unread);
        notifications.set(data.notifications);
        filters.set(defaultFilters);
    }
    isLoadNotifications.set(false);
};
const getMoreNotifications = async () => {
    isLoadNotifications.set(true);
    filters.update(data => ({ limit: DEFAULT_LENGTH_LIST, offset: data.offset + 10 }));
    const { success, data } = await apiGetNotifications(get(filters));
    if (success) {
        notificationsCount.set(data.total);
        notificationsUnreadCount.set(data.unread);
        notifications.update((notes) => notes.concat(data.notifications));
    }
    isLoadNotifications.set(false);
};
const setAsReadNotifications = async (ids) => {
    const { success } = await apiUpdateNotifications(ids);
    return success;
};
export const notificationsStore = {
    notifications,
    notificationsCount,
    notificationsUnreadCount,
    getNotifications,
    setAsReadNotifications,
    getMoreNotifications,
    isLoadNotifications
};
