import { api } from "api/index";
import { endpoints } from "api/endpoints";
import { projectOfficeNormalize } from "../../normalize/projectOfficeNormalize";
import { stringify } from "query-string";
const getServerQueryString = (query) => {
    if (query) {
        return stringify({ date_from: query.dateFrom, date_to: query.dateTo });
    }
    return '';
};
async function apiGetProjectOffice(params) {
    var _a;
    try {
        const { data: { success, message }, } = await api({
            url: endpoints.auth.projectOffice + `?${getServerQueryString(params)}`,
            method: "GET",
        });
        return { success, data: projectOfficeNormalize(message) };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if (errorAxios.response.data) {
                const { success, message } = errorAxios.response.data;
                return {
                    success,
                    message,
                };
            }
        }
    }
}
export default apiGetProjectOffice;
