export const convertToNormalAct = ({ id, name, date, status, hours, price, sort, project, milestone, scan_file, is_approved }) => ({
    id,
    name,
    date,
    status,
    sort,
    hours,
    price,
    project,
    milestone,
    scanFile: scan_file,
    isApproved: is_approved
});
export const actsServerToNormalize = (data) => {
    return data.map(convertToNormalAct);
};
const convertToServerAct = ({ id, name, date, status, hours, sort, price, scanFile, isApproved, project, milestone }) => ({
    id,
    name,
    date,
    sort,
    status,
    hours,
    price,
    project,
    milestone,
    scan_file: scanFile,
    is_approved: isApproved
});
export const actsNormalizeToServer = (data) => {
    return data.map(convertToServerAct);
};
export const actsFiltersToServer = (data) => {
    const convertToServerActFilter = ({ dateTo = undefined, dateFrom = undefined, projectId = undefined, milestoneId = undefined }) => (Object.assign(Object.assign(Object.assign(Object.assign({}, (dateTo ? { date_to: dateTo } : {})), (dateFrom ? { date_from: dateFrom } : {})), (projectId ? { project_id: projectId } : {})), (milestoneId ? { milestone_id: milestoneId } : {})));
    return convertToServerActFilter(data);
};
