<script lang="typescript">
  import AuthSendEmail from "./AuthSendEmail/index.svelte";
  import AuthSendCode from "./AuthSendCode/index.svelte";

  import { authStore } from "stores/authStore";

  import LayoutAuth from "../layout/LayoutAuth/index.svelte";

  const { isGetCodeSuccess } = authStore;
</script>

<LayoutAuth>
  {#if $isGetCodeSuccess === null || $isGetCodeSuccess === false}
    <AuthSendEmail />
  {:else}
    <AuthSendCode />
  {/if}
</LayoutAuth>
