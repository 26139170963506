import { writable } from "svelte/store";
import { apiGetProducts } from "../api/methods/products/apiGetProducts";
const products = writable(null);
const isLoading = writable(false);
const error = writable(null);
const getProducts = async () => {
    isLoading.set(true);
    const result = await apiGetProducts();
    if (result.success)
        products.set(result.data);
    else
        error.set(result.message);
    isLoading.set(false);
};
export const productsStore = {
    getProducts,
    products,
    isLoading,
};
