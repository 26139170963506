<script lang="typescript">
  import { beforeUpdate, afterUpdate, onMount } from "svelte";
  import { Icon } from "@smui/chips";
  import IconButton from "@smui/icon-button";

  import { paymentStore } from "stores/paymentStore";
  import { authStore } from "stores/authStore";
  import { formatDate } from "helpers/helpers";

  import Spinner from "../../../shared/Spinner/index.svelte";
  import TypingBlock from "./TypingBlock.svelte";

  const { userData } = authStore;
  const { isMessagesLoading, messages, getMessages, addMessage, paymentInformation } =
    paymentStore();

  let scrollable: HTMLDivElement;
  let autoscroll: boolean;

  beforeUpdate(() => {
    autoscroll =
      scrollable && scrollable.offsetHeight + scrollable.scrollTop > scrollable.scrollHeight - 20;
  });

  afterUpdate(() => {
    if (autoscroll) scrollable.scrollTo(0, scrollable.scrollHeight);
  });

  onMount(() => {
    //@ts-ignore
    $userData?.id && getMessages($userData?.id, id);
  });

  export let id: number;
</script>

<div class="chat-container">
  <div class="chat">
    <div class="chat-header">
      <h1>
        Период ({$paymentInformation?.registry.date_from} - {$paymentInformation?.registry.date_to})
      </h1>
      <div class="chat-buttons">
        {#if $isMessagesLoading}
          <div class="spinner">
            <Spinner />
          </div>
        {/if}
        <IconButton class="material-icons" on:click={getMessages($userData?.id || 0, id)}
          >update</IconButton
        >
      </div>
    </div>

    <div class="scrollable" bind:this={scrollable}>
      {#each $messages as message}
        <article class={$userData?.id === message.user?.id ? "user" : "author"}>
          <div class="author-name">{message.user?.name}</div>
          <span class="message">{message.text}</span>
          <div class="message-date">{message.date && formatDate(message.date)}</div>
          <div class="message-is-read ">
            <Icon class="material-icons">{message.is_read ? "done_all" : "done"}</Icon>
          </div>
        </article>
      {/each}
    </div>
    <TypingBlock {addMessage} {id} />
  </div>
</div>

<style lang="scss">
  .chat-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    .chat {
      display: flex;
      flex-direction: column;
      max-height: 500px;
      width: 70%;

      .chat-header {
        display: flex;
        justify-content: space-between;

        h1 {
          display: flex;
          align-items: center;
        }
      }

      .chat-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 120px;

        .spinner {
          margin-right: 15px;
        }
      }

      .scrollable {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        border-top: 1px solid #eee;
        margin: 0 0 0.5em 0;
        overflow-y: auto;

        article {
          position: relative;
          margin: 20px 10px;
          min-width: 160px;
          max-width: 60%;
        }

        .author-name {
          position: absolute;
          font-size: 13px;
          top: -17px;
          color: #6f6c88;
        }

        .message-date {
          position: absolute;
          bottom: -20px;
          left: 5px;
          font-size: 13px;
          color: #6f6c88;
        }

        .message-is-read {
          position: absolute;
          bottom: -20px;
          right: 5px;
        }

        .message {
          width: 100%;
          padding: 10px 15px;
          display: inline-block;
          word-wrap: break-word;
        }

        .user {
          align-self: flex-end;

          .message {
            background-color: #0074d9;
            color: white;
            border-radius: 1em 1em 0 1em;
            word-break: break-all;
          }

          .author-name {
            right: 5px;
          }
        }

        .author {
          align-self: flex-start;
          .message {
            background-color: #eee;
            border-radius: 1em 1em 1em 0;
          }

          .author-name {
            left: 5px;
          }
        }
      }
    }
  }
</style>
