<script lang="ts">
import type { ItemsDividedByStatusInterface, SortableItemType } from "interfaces/Sort.interface";

  import CanbanColumn from "./CanbanColumn/index.svelte";

  export let columns: ItemsDividedByStatusInterface<SortableItemType>[];
  export let itemType: any;
  export let changeSort: any;
  export let customOptionsToDrug: any;
  export let cardThis: any;
  export let entityIdName: any;

  function sortHandler({ detail: { itemType, sortParams } }) {
    if (itemType && sortParams) changeSort(sortParams);
  }
</script>

<div class="Canban">
  {#if columns && itemType}
    {#each columns as column (column.id)}
      <CanbanColumn
        {cardThis}
        {column}
        {itemType}
        {customOptionsToDrug}
        {entityIdName}
        on:sort={sortHandler}
      />
    {/each}
  {/if}
</div>

<style lang="scss">
  .Canban {
    display: flex;

    :global(.card) {
      margin-bottom: 10px;
      padding: 16px;
      width: 260px;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      box-sizing: border-box;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      font-size: 13px;
      line-height: 18px;
      font-family: "Open Sans", sans-serif;
    }

    :global(.card__name) {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      transition: 0.4s all;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid black;
      }
    }

    :global(a) {
      &:hover {
        text-decoration: none;
      }
    }

    :global(.card__hour) {

      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 14px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
</style>
