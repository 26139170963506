import apiGetMessages from "../api/methods/payouts/apiGetMessages";
import apiGetPaymentInformation from "../api/methods/payouts/apiGetPaymentInformation";
import apiPostMessage from "../api/methods/payouts/apiPostMessage";
import apiPutApprovePayment from "../api/methods/payouts/apiPutApprovePayment";
import apiPutMessageRead from "../api/methods/payouts/apiPutMessageRead";
import { mapReadMessages } from "../helpers/helpers";
import { writable } from "svelte/store";
const headCells = [
    "Корректировка",
    "Примечание",
    "Рассчитано",
    "Итого",
    "Безнал",
    "Наличные",
    "Подтверждено",
    "Выплачено (безнал)",
    "Выплачено (нал)"
];
const paymentInformation = writable(null);
const messages = writable([]);
const isMessagesLoading = writable(false);
const isLoading = writable(false);
const error = writable(null);
const getPaymentInformation = async (id) => {
    isLoading.set(true);
    const result = await apiGetPaymentInformation(id);
    if (result.success)
        paymentInformation.set(result.data);
    else
        error.set(result.message);
    isLoading.set(false);
};
const putApprovePayment = async (id) => {
    await apiPutApprovePayment(id);
    await getPaymentInformation(id);
};
const putMessageRead = async (id, messages) => {
    await apiPutMessageRead(id, messages);
};
const getMessages = async (userId, registryId) => {
    isMessagesLoading.set(true);
    const readMessages = await apiGetMessages(registryId);
    if (readMessages.success) {
        if (mapReadMessages(userId, readMessages.data).length) {
            await putMessageRead(registryId, mapReadMessages(userId, readMessages.data));
        }
        const result = await apiGetMessages(registryId);
        messages.set(result.data.reverse());
    }
    else {
        error.set(readMessages.message);
    }
    isMessagesLoading.set(false);
};
const addMessage = async (userId, registryId, text) => {
    await apiPostMessage(registryId, text);
    await getMessages(userId, registryId);
};
export const paymentStore = () => {
    return {
        getPaymentInformation,
        putApprovePayment,
        getMessages,
        addMessage,
        messages,
        isMessagesLoading,
        paymentInformation,
        isLoading,
        headCells
    };
};
