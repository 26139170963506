<script lang="typescript">
  import cn from "classnames";

  export let size = 30;
  export let childClass = "";

  const currentClass = cn("spinner", $$props.class);
  const style = `height: ${size}px; width: ${size}px;`
</script>

<div class={currentClass} style={style}>
  {#each Array(12).fill(undefined) as _, i}
    <div class={cn("child", `circle${i + 1}`, childClass)} />
  {/each}
</div>

<style lang="scss">
  .spinner {
    position: relative;
  }

  .spinner .child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .spinner .child:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #9d30c5;
    border-radius: 100%;
    animation: circleBounceDelay 1.2s infinite ease-in-out both;
  }

  @for $i from 1 through 11 {
    .spinner {
      & > .circle#{$i + 1} {
        transform: rotate(#{$i * 30}deg);

        &::before {
          animation-delay: -#{1.2 - $i / 10}s;
        }
      }
    }
  }

  @keyframes circleBounceDelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
</style>
