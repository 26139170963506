import { __rest } from "tslib";
import { derived, get, writable } from "svelte/store";
import apiGetDetailMilestone from "../api/methods/milestones/apiGetDetailMilestone";
import apiGetLiteMilestones from "../api/methods/milestones/apiGetLiteMilestones";
import apiGetStatuses from "../api/methods/apiGetStatuses";
import apiPutMilestone from "../api/methods/milestones/apiPutMilestone";
import { getNewSortValue } from "./utils/sortUtils";
import itemsUpdater from "./utils/updater";
import { showArchiveStore } from "./showArchivedStore";
const statusColors = {
    1: "#00C4B4",
    2: "#ae6161",
    3: "#528845",
    4: "#4285f4",
    5: "#9ce2ee",
    6: "#797777",
    7: "#0097a7",
};
const milestones = writable(null);
const detailMilestone = writable(null);
const statuses = writable(null);
const errorMessage = writable(null);
const statusColumns = derived([milestones, statuses], ([$milestones, $statuses]) => {
    if ($milestones && $statuses) {
        return $statuses.map((status) => {
            const items = $milestones
                .filter((milestone) => milestone.status.id === status.id)
                .sort((a, b) => a.sort - b.sort);
            return Object.assign(Object.assign({}, status), { items, revenue: items.reduce((acc, prev) => +acc + prev.revenue, 0) });
        });
    }
});
async function initMilestones() {
    try {
        await Promise.all([getLiteMilestones(), getMilestonesStatuses()]);
    }
    catch (error) {
        errorMessage.set(error);
    }
}
async function getLiteMilestones(filters) {
    const { success, data, message } = await apiGetLiteMilestones(get(showArchiveStore.showArchivedFlag), filters);
    if (success) {
        milestones.set(data);
    }
    else {
        errorMessage.set(message);
        milestones.set([]);
        return { message: false };
    }
}
async function getDetailMilestone(milestoneId) {
    const { success, data, message } = await apiGetDetailMilestone(milestoneId);
    if (success) {
        detailMilestone.set(data);
    }
    else {
        errorMessage.set(message);
    }
}
async function getMilestonesStatuses() {
    const { success, data, message } = await apiGetStatuses("milestones");
    if (success) {
        statuses.set(data.map((status, i) => { var _a; return (Object.assign(Object.assign({}, status), { color: (_a = statusColors[i + 1]) !== null && _a !== void 0 ? _a : "#474747" })); }));
    }
    else {
        errorMessage.set(message);
    }
}
async function changeMilestoneSort(sortParams) {
    const { itemId, statusId, type } = sortParams;
    const editedParams = {};
    if (type === "adding") {
        editedParams.statusId = statusId;
    }
    const destinationArray = get(statusColumns).find((stat) => stat.id === statusId).items;
    editedParams.sort = getNewSortValue({
        destinationArray,
        sortParams,
    });
    await saveMilestone(editedParams, itemId);
}
async function saveMilestone(changedParams, milestoneId) {
    const { success, message } = await apiPutMilestone(changedParams, milestoneId);
    const modifiedMilestone = get(milestones).find((ml) => ml.id === milestoneId);
    if (!success) {
        errorMessage.set(message);
        return { message, success };
    }
    const { statusId } = changedParams, restChangedParams = __rest(changedParams, ["statusId"]);
    if (statusId) {
        modifiedMilestone.status = get(statuses).find((st) => st.id === changedParams.statusId);
    }
    milestones.update(itemsUpdater(Object.assign({}, modifiedMilestone, restChangedParams)));
    errorMessage.set(null);
    return { message, success };
}
export const milestonesStore = {
    statuses,
    milestones,
    errorMessage,
    statusColumns,
    detailMilestone,
    saveMilestone,
    initMilestones,
    getLiteMilestones,
    changeMilestoneSort,
    getDetailMilestone,
};
