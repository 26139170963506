import { writable, get } from "svelte/store";
import { apiGetDaysOff } from "api/methods/daysOff/apiGetDaysOff";
import { apiCreateDayOff } from "api/methods/daysOff/apiCreateDayOff";
import { apiUpdateDayOff } from "api/methods/daysOff/apiUpdateDayOff";
import { apiDeleteDayOff } from "api/methods/daysOff/apiDeleteDayOff";
import { apiGetDaysOffOfUser } from "api/methods/daysOff/apiGetPesonalDaysOff";
import { getCurrentDay } from "helpers/getCurrentDay";
import { DEFAULT_LENGTH_LIST } from "../constants/constants";
const defaultFilters = { limit: DEFAULT_LENGTH_LIST, offset: 0 };
const daysOff = writable([]);
const isLoadingDaysOff = writable(false);
const errorMessage = writable(null);
const endDate = writable("");
const startDate = writable("");
const description = writable("");
const userId = writable("");
const isUpdatingDayOff = writable(false);
const filters = writable(defaultFilters);
const currentPage = writable(1);
const total = writable(0);
const getDaysOff = async (filters = defaultFilters) => {
    isLoadingDaysOff.set(true);
    const { success, data, message } = await apiGetDaysOff(filters);
    isLoadingDaysOff.set(false);
    if (success) {
        errorMessage.set(null);
        daysOff.set(data.daysOff);
        total.set(data.total);
    }
    else {
        errorMessage.set(message);
        daysOff.set([]);
        total.set(0);
    }
};
const getPersonalDaysOff = async (user_id, filters = {}) => {
    isLoadingDaysOff.set(true);
    const { success, data } = await apiGetDaysOffOfUser(user_id, filters);
    daysOff.set(success ? data.daysOff : []);
    total.set(success ? data.total : 0);
    isLoadingDaysOff.set(false);
};
const updateFilters = async (newFilters) => {
    filters.update(value => (Object.assign(Object.assign({}, value), newFilters)));
    const queryFilters = get(filters);
    await getDaysOff(queryFilters);
};
const clearDaysOffStore = () => {
    isLoadingDaysOff.set(false);
    errorMessage.set(null);
    daysOff.set([]);
    currentPage.set(1);
    total.set(0);
    filters.set(defaultFilters);
};
const setDayOffInModal = ({ startDate: start = getCurrentDay(), endDate: end = "", description: desc = null, userId: user = "" }) => {
    startDate.set(start);
    endDate.set(end);
    description.set(desc !== null && desc !== void 0 ? desc : "");
    userId.set(user.toString());
};
const daysOffChangeFactory = (fn) => {
    return async (...params) => {
        isUpdatingDayOff.set(true);
        const { success, message } = await fn(...params);
        isUpdatingDayOff.set(false);
        filters.update((value) => (Object.assign(Object.assign({}, value), defaultFilters)));
        currentPage.set(1);
        if (success)
            getDaysOff(get(filters));
        return message;
    };
};
const dayOff = {
    create: daysOffChangeFactory(apiCreateDayOff),
    update: daysOffChangeFactory(apiUpdateDayOff),
    delete: daysOffChangeFactory(apiDeleteDayOff),
};
export const daysOffStore = {
    dayOff,
    daysOff,
    errorMessage,
    getPersonalDaysOff,
    isLoadingDaysOff,
    getDaysOff,
    clearDaysOffStore,
    setDayOffInModal,
    endDate,
    startDate,
    description,
    userId,
    isUpdatingDayOff,
    updateFilters,
    filters,
    currentPage,
    total,
    defaultFilters
};
