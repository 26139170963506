<script lang="typescript">
  import IconButton from "@smui/icon-button";
  export let isDone: boolean;
</script>

{#if isDone}
  <IconButton class="material-icons" disabled>done</IconButton>
{:else}
  <IconButton class="material-icons" disabled>check_box_outline_blank</IconButton>
{/if}
