import { stringify } from "query-string";
const milestonesEndpoints = {
    root: "milestones",
    statuses: "milestone-statuses",
    milestoneById(milestoneId) {
        return `${this.root}/${milestoneId}`;
    },
    logs(milestoneId) {
        return `/${this.root}/${milestoneId}/logs`;
    },
    all(filters = {}) {
        let queryParams = stringify(filters);
        if (queryParams)
            queryParams = "?" + queryParams;
        return `/${this.root}${queryParams}`;
    },
};
export { milestonesEndpoints };
