<script lang="typescript">
  import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
  import { mapCompanyInformation } from "helpers/helpers";
import type { IModal } from "interfaces/Common.interface";
  import { companiesStore } from "stores/companiesStore";
  import Spinner from "../../../../shared/Spinner/index.svelte";
  const { companies, isLoading, companiesCells, companyBySuggestion, deleteCompany } =
    companiesStore;
  export let isOurCompany: boolean;
  export let isEditMode: boolean;
  export let editingCompanyId: number | null;
  export let companyModal: IModal | undefined;
  export let companyModalClose: () => void;
  export let selectedCompanyType: any;
  export let inn: string;

  const handleEdit = async (item: any) => {
    inn = item.details.inn;
    companyBySuggestion.set(mapCompanyInformation(undefined, item.details.inn, item));
    //@ts-ignore
    isOurCompany = $companyBySuggestion.is_our_company.value;
    //@ts-ignore
    selectedCompanyType = $companyBySuggestion.type_id.value;
    isEditMode = true;
    editingCompanyId = item.id;
    companyModal?.open();
  };

  const handleDelete = async (item: any) => {
    const res = await deleteCompany(item.id);

    if (res.success) {
      companyModalClose();
    }
  };

</script>

<DataTable>
  <Head>
    <Row>
      {#each companiesCells as cell}
        <Cell width="200px">{cell}</Cell>
      {/each}
    </Row>
  </Head>
  <Body>
    {#if $isLoading || !$companies.companies}
      <Spinner />
    {:else}
      {#each $companies.companies as item}
        <Row>
          <Cell><a href="/" on:click|preventDefault={() => handleEdit(item)}>{item.name || ""}</a></Cell>
          <Cell>{item.details.inn || ""}</Cell>
          <Cell>{item.details.name || ""}</Cell>
          <Cell>{item.details.kpp || ""}</Cell>
          <Cell>{item.details.bank.bic || ""}</Cell>
          <Cell>{item.details.bank.name || ""}</Cell>
          <Cell>{item.details.bank["correspondent account"] || ""}</Cell>
          <Cell>{item.details.payment_account || ""}</Cell>
          <Cell>{item.details.address || ""}</Cell>
          <Cell>{item.type.name || ""}</Cell>
          <Cell>{item.is_our_company ? "да" : "нет"}</Cell>
          <Cell>
            <a href="/" on:click|preventDefault={() => handleEdit(item)}>Редактировать</a>&nbsp;
            <a class="delete" href="/" on:click|preventDefault={() => handleDelete(item)}>Удалить</a
            >
          </Cell>
        </Row>
      {/each}
    {/if}
  </Body>
</DataTable>

<style lang="scss">
  .delete {
    color: red;
    transition: all 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }

</style>
