import apiDeleteUserTransaction from "api/methods/users/apiDeleteUserTransaction";
import apiGetPersonalTransactions from "../api/methods/users/apiGetPersonalTransaction";
import { apiGetUser } from "../api/methods/users/apiGetUser";
import apiGetUserTransactions from "api/methods/users/apiGetUserTransactions";
import { apiGetUsers } from "api/methods/users/apitGetUsers";
import apiGetUsersCoins from "api/methods/users/apiGetUsersCoins";
import { apiGetUsersDetailed } from "../api/methods/users/apiGetUsersDetails";
import apiPostUserTransaction from "api/methods/users/apiPostUserTransaction";
import apiPutUserTransaction from "api/methods/users/apiPutUserTransaction";
import { apiUpdateUser } from "../api/methods/users/apiUpdateUser";
import { writable } from "svelte/store";
const users = writable([]);
const usersDetailed = writable([]);
const user = writable(null);
const usersCoins = writable(null);
const coinTransactions = writable(null);
const isFetchUsers = writable(false);
const isFetchBalance = writable(false);
const isFetchTransactions = writable(false);
const isSaveTransaction = writable(false);
const error = writable(null);
const getUsers = async () => {
    isFetchUsers.set(true);
    const { success, data, message } = await apiGetUsers();
    isFetchUsers.set(false);
    if (success) {
        error.set(null);
        users.set(data);
    }
    else {
        error.set(message);
        users.set([]);
    }
};
const getUsersDetailed = async (filters) => {
    isFetchUsers.set(true);
    const { success, data, message } = await apiGetUsersDetailed(filters);
    isFetchUsers.set(false);
    if (success) {
        error.set(null);
        usersDetailed.set(data.users);
    }
    else {
        error.set(message);
        users.set([]);
    }
};
const getUser = async (id) => {
    isFetchUsers.set(true);
    const { success, data, message } = await apiGetUser(id);
    isFetchUsers.set(false);
    if (success) {
        error.set(null);
        user.set(data);
    }
    else {
        error.set(message);
        user.set(null);
    }
    return success;
};
const updateUser = async (info, userId) => {
    return await apiUpdateUser(info, userId);
};
const updateUserStore = async (info, field) => {
    user.update(user => {
        user[field] = info;
        return user;
    });
};
const updateUsersStore = async (info, field, userId) => {
    users.update(users => {
        const id = users.findIndex(item => item.id === userId);
        users[id][field] = info;
        return users;
    });
};
const updateUsersDetailedStore = async (info, field, userId) => {
    usersDetailed.update(users => {
        const id = users.findIndex(item => item.id === userId);
        users[id][field] = info;
        return users;
    });
};
const getUsersCoins = async () => {
    isFetchBalance.set(true);
    const { success, data, message } = await apiGetUsersCoins();
    if (!success) {
        error.set(message);
        isFetchBalance.set(false);
        return;
    }
    usersCoins.set(data);
    isFetchBalance.set(false);
};
const getUserTransactions = async (queryObj) => {
    isFetchTransactions.set(true);
    const { data, success } = await apiGetUserTransactions(queryObj);
    if (success)
        coinTransactions.set(data);
    isFetchTransactions.set(false);
};
const getPersonalTransactions = async (id, withProducts) => {
    isFetchTransactions.set(true);
    const { data, success } = await apiGetPersonalTransactions(id, withProducts);
    if (success)
        coinTransactions.set(data);
    isFetchTransactions.set(false);
};
const deleteUserTransaction = async (id) => {
    return await apiDeleteUserTransaction(id);
};
const changeUserTransaction = async (transactionToChange, transactionId) => {
    return await apiPutUserTransaction(transactionToChange, transactionId);
};
const saveUserTransaction = async (transactionToSave) => {
    isSaveTransaction.set(true);
    const response = await apiPostUserTransaction(transactionToSave);
    isSaveTransaction.set(false);
    return response;
};
export const usersStore = {
    getUsers,
    getUsersCoins,
    getUserTransactions,
    saveUserTransaction,
    getPersonalTransactions,
    deleteUserTransaction,
    changeUserTransaction,
    isSaveTransaction,
    isFetchBalance,
    coinTransactions,
    usersCoins,
    isFetchUsers,
    isFetchTransactions,
    users,
    error,
    getUsersDetailed,
    usersDetailed,
    getUser,
    user,
    updateUser,
    updateUsersStore,
    updateUserStore,
    updateUsersDetailedStore
};
