export const checkKPP = (value) => {
    const valueToString = value ? value.toString() : "";
    if (valueToString.length !== 9)
        return false;
    if (!valueToString.match(/\d{4}[\dA-Z][\dA-Z]\d{3}/))
        return false;
    return true;
};
export const checkINN = (value) => {
    //преобразуем в строку
    value = "" + value;
    if (!/^\d{10}$/.test(value) && !/^\d{12}$/.test(value)) {
        return false;
    }
    //преобразуем в массив
    value = value.split("");
    //для ИНН в 10 знаков
    if (value.length === 10 &&
        +value[9] ===
            ((2 * value[0] +
                4 * value[1] +
                10 * value[2] +
                3 * value[3] +
                5 * value[4] +
                9 * value[5] +
                4 * value[6] +
                6 * value[7] +
                8 * value[8]) %
                11) %
                10) {
        return true;
    }
    //для ИНН в 12 знаков
    return (value.length === 12 &&
        +value[10] ===
            ((7 * value[0] +
                2 * value[1] +
                4 * value[2] +
                10 * value[3] +
                3 * value[4] +
                5 * value[5] +
                9 * value[6] +
                4 * value[7] +
                6 * value[8] +
                8 * value[9]) %
                11) %
                10 &&
        +value[11] ===
            ((3 * value[0] +
                7 * value[1] +
                2 * value[2] +
                4 * value[3] +
                10 * value[4] +
                3 * value[5] +
                5 * value[6] +
                9 * value[7] +
                4 * value[8] +
                6 * value[9] +
                8 * value[10]) %
                11) %
                10);
};
export const checkBIK = (value) => {
    if (value) {
        if (!/^\d{9}$/.test(value))
            return false;
        const thirdPart = value.slice(-3);
        if (+thirdPart === 0 || +thirdPart === 1 || +thirdPart === 2)
            return true;
        return +thirdPart >= 50 && +thirdPart < 1000;
    }
    else {
        return false;
    }
};
export const checkPaymetAccount = (value, bik) => {
    const valueToString = value ? value.toString() : "";
    if (checkBIK(bik)) {
        if (!/[^0-9]/.test(valueToString) && valueToString.length === 20) {
            const bikRs = bik.toString().slice(-3) + valueToString;
            let checkSum = 0;
            const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
            for (var i in coefficients) {
                checkSum += coefficients[i] * (Number(bikRs[i]) % 10);
            }
            return checkSum % 10 === 0;
        }
    }
    return false;
};
export const checkCorrespondentAccount = (value, bik) => {
    const valueToString = value ? value.toString() : "";
    if (bik) {
        if (checkBIK(bik)) {
            if (!/[^0-9]/.test(valueToString) && valueToString.length === 20) {
                const bikKs = "0" + bik.slice(4, 6) + valueToString;
                let checkSum = 0;
                const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
                for (var i in coefficients) {
                    checkSum += coefficients[i] * (Number(bikKs[i]) % 10);
                }
                return checkSum % 10 === 0;
            }
        }
    }
    return false;
};
export const checkCheckingAccount = (value) => /^\d{3}-\d{2}-\d{3}-\d{1}-\d{4}-\d{6}$/.test(value);
