<script lang="typescript">
  import Textfield from "@smui/textfield";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Select from "svelte-select";
  export let value;
  export let selected: number;
  export let checked: boolean;
  export let label: string;
  export let invalidValue: boolean;
  let invalidData = false;
  export let validate: (value: string | number, bik?: string | number) => boolean;
  export let type: "text" | "number" | "checkbox" | "select";
  import { companiesStore } from "stores/companiesStore";

  const {
    companyBySuggestion,
  } = companiesStore;

  let items = [
    "Юридическое лицо",
    "Индивидуальный предприниматель",
    "Самозанятый",
    "Физическое лицо"
  ];
  const validateInput = (e) => {
    invalidData = validate(e.target.value, $companyBySuggestion.req_bank_bik?.value);
  };

</script>
{#if (type === "text" || type === 'number')}
  <Textfield
    class="companies-modal__field"
    on:input={validateInput}
    invalid={invalidValue || invalidData}
    bind:value
    {label}
    {type}
  />
  {#if invalidValue}<div class="error">{invalidValue}</div>{/if}
{:else if type === "select"}
  <Select
    {items}
    containerClasses="select-type"
    placeholder="Юридическое лицо"
    selectedValue={items.find((_item, i) => i + 1 == selected)}
    on:select={(e) => (selected = e.detail.index + 1)}
  />
  {#if invalidValue}<div class="error">{invalidValue}</div>{/if}
{:else}
  <FormField>
    <Checkbox bind:checked />
    <span slot="label">Наше юр. лицо?</span>
  </FormField>
{/if}

<style lang="scss">
  :global {
    .select-type {
      margin-top: 15px;
      margin-bottom: 10px;
      &.focused {
        border-color: rgb(98, 0, 238) !important;
      }
    }
  }

  .error {
    color: var(--red);
  }

  :global(.mdc-form-field) {
    margin-left: -10px;
  }

</style>
