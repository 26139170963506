import { api } from "api/index";
import { endpoints } from "api/endpoints";
export const apiGetCompanies = async () => {
    var _a, _b;
    try {
        const { data: { success, message } } = await api({
            url: endpoints.companies.getCompanies(),
            method: "GET"
        });
        return { success, data: message, message: '' };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if ((_b = errorAxios.response) === null || _b === void 0 ? void 0 : _b.data) {
                const { success, message } = errorAxios.response.data;
                return {
                    success,
                    message,
                    data: { total: 0, companies: [] }
                };
            }
        }
    }
};
