import { stringify } from "query-string";
const root = {
    invoices: "/invoices",
    positionUnits: "/invoice-position-units",
    statuses: "/invoice-statuses"
};
const invoicesEndpoints = {
    getInvoices: (params) => `${root.invoices}?${stringify(params)}`,
    getInvoicePositionUnits: () => root.positionUnits,
    getInvoiceStatuses: () => root.statuses,
    postInvoice: () => root.invoices,
    getInvoice: (id) => `${root.invoices}/${id}`,
    putInvoice: (id) => `${root.invoices}/${id}`,
    deleteInvoice: (id) => `${root.invoices}/${id}`,
    postInvoiceFile: (id) => `${root.invoices}/${id}/file`,
};
export { invoicesEndpoints };
