<script lang="typescript">
  import type { IProduct } from "interfaces/Product.interface";
  import Coins from "../../../shared/Coins/index.svelte";

  export let item: IProduct
  export let shadow: boolean = false;
  const { picture, name, description, cost } = item
</script>

<div class="wrap card" class:shadow>
  <img src={picture} alt={name}/>
  <div class="content">
    <h3>{name}</h3>
    <p class="desc">{description}</p>
    <p>
      <Coins coins={cost}/>
    </p>

  </div>

</div>

<style lang="scss">
  .shadow {
    opacity: .5
  }

  .wrap {
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }

  .content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .desc {
    color: var(--gray-text);
    flex-grow: 1;
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 10px;
  }

  img {
    height: 200px;
    object-fit: cover;
  }

</style>
