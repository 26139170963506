function projectOfficeNormalize(data) {
    const { id, name, create_date, group_head, teamlead, users } = data;
    return {
        id,
        name,
        teamlead,
        users,
        createDate: create_date,
        groupHead: group_head,
    };
}
function summaryStatsNormalize(data) {
    const { billable_hours, logged_hours, possible_hours, realization_rate, utilization_rate, premium_fund, confirmed_acts_hours, acts_hours, } = data;
    return {
        billableHours: billable_hours,
        loggedHours: logged_hours,
        possibleHours: possible_hours,
        realizationRate: realization_rate,
        utilizationRate: utilization_rate,
        premiumFund: premium_fund,
        actsHours: acts_hours,
        confirmedActsHours: confirmed_acts_hours,
    };
}
function userStatsNormalize(data) {
    const { name, utilization_rate, realization_rate, possible_hours, logged_hours, billable_hours, } = data;
    return {
        name,
        utilizationRate: utilization_rate,
        realizationRate: realization_rate,
        possibleHours: possible_hours,
        loggedHours: logged_hours,
        billableHours: billable_hours,
    };
}
function wholeOfficeStatsNormalize(data) {
    const { user_data, summary } = data;
    return {
        summary: summaryStatsNormalize(summary),
        userData: user_data.map(userStatsNormalize),
    };
}
export { projectOfficeNormalize, wholeOfficeStatsNormalize };
