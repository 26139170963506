<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  import format from "date-fns/format";

  import Radio from "@smui/radio";
  import FormField from "@smui/form-field";
  import Dialog, { Content, Title } from "@smui/dialog";
  import Button from "@smui/button";

  import TextInput from "../../../shared/Fields/Text.svelte";
  import NumberInput from "../../../shared/Fields/Number.svelte";
  import Select from "../../../shared/CustomSelect/index.svelte";
  import Spinner from "../../../shared/Spinner/index.svelte";

  import type { ICoinBalance, TTransactionToSaveNormalize } from "interfaces/Coins.interface";

  import { productsStore } from "stores/productsStore";

  const { getProducts, products } = productsStore

  type TExternalValues = { name: string; amount: number; operation: number, productId: number } | null;

  export let usersCoins: ICoinBalance[];
  export let selectedUserId: number | undefined;
  export let error: string = "";
  export let editMode: boolean = false;
  export let isModalOpen: boolean = false;
  export let isSaveTransaction: boolean = false;
  export let externalValues: TExternalValues = null;


  const dispatch = createEventDispatcher();
  let addAndEditDialog;
  let hasError = false

  const operationOptions = [
    { operation: 1, name: "Начисление" },
    { operation: -1, name: "Списание" }
  ];

  const values = {
    name: {
      isRequired: true,
      isValid: (value) => !!value,
      value: "" as string
    },
    userId: {
      isRequired: true,
      isValid: (value) => !!value,
      value: selectedUserId ? selectedUserId : null,
    },
    operation: {
      isRequired: true,
      isValid: (value) => [ 1, -1 ].includes(value),
      value: 1
    },
    productId: {
      isRequired: false,
      isValid: (value) => typeof value === "number",
      value: null
    },
    amount: {
      isRequired: true,
      isValid: (value) => value > 0,
      value: 0
    },
    date: {
      isRequired: true,
      isValid: (value) => !!value,
      value: format(new Date(), "yyyy-MM-dd") as string
    }
  };

  const checkValid = (values) => {
    let isValid = true;
    Object.keys(values).some((field) => {
      const input = values[field];
      if (input.isRequired && !input.isValid(input.value)) {
        isValid = false;
      }
      return !input.isValid(input.value);
    });
    return isValid;
  };

  const handleChange = (name, value) => {
    values[name].value = value;
  };

  const handleSave = () => {
    if (!checkValid(values)) {
      hasError = true
      error = "Введите корректные значения";
      return;
    }
    hasError = false
    error = "";


    let resultObject: TTransactionToSaveNormalize = {
      name: values.name.value,
      date: values.date.value,
      value: values.amount.value * values.operation.value,
      //@ts-ignore
      userId: values.userId.value,
      productId: values.productId.value
    };

    dispatch("save", resultObject);
  };

  function manageModal(isModalOpen: boolean) {
    if (addAndEditDialog) {
      if (isModalOpen) {
        addAndEditDialog.open();
      } else {
        addAndEditDialog.close();
        clearForm();
      }
    }
  }

  function clearForm() {
    values.name.value = "";
    values.amount.value = 0;
    values.userId.value = selectedUserId ? selectedUserId : null;
    values.operation.value = 1;
    values.productId.value = null;
  }

  function closeHandler() {
    dispatch("close");
    error = "";
  }

  function manageExternalValues(externalValues: TExternalValues) {
    if (externalValues) {
      Object.keys(externalValues).forEach((key) => {
        values[key].value = externalValues[key];
      });
    }
  }

  $: manageModal(isModalOpen);
  $: manageExternalValues(externalValues);

  const handleSelectProduct = (e) => {
    if (e) {
      handleChange("productId", e.detail.id)
      handleChange("operation", -1)
      handleChange("amount", e.detail.cost)
    } else {
      handleChange("productId", null)
    }
  }

  onMount(() => {
    if (!$products) getProducts()
  })
</script>

<div class="coinModal">
  <Dialog bind:this={addAndEditDialog} on:MDCDialog:closed={closeHandler}>
    <Title>{editMode ? "Редактирование транзакции" : "Добавление транзакции"}</Title>
    <Content>
      {#if !selectedUserId}
        <div class="fieldWrap">
          <Select
            placeholder="Выберите пользователя"
            selectedValue={usersCoins.find((user) => values.userId.value === user.id)}
            items={usersCoins}
            on:select={(e) => handleChange("userId", e.detail.id)}
            on:clear={() => (values.userId.value = null)}
          />
        </div>
      {/if}
      <div class="fieldWrap">
        <Select
          placeholder="Выберите продукт"
          selectedValue={$products?.find?.((product) => values.productId.value === product.id)}
          items={$products ?? []}
          on:select={handleSelectProduct}
          on:clear={() =>handleSelectProduct(null)}
        />
      </div>
      <div class="fieldWrap">
        <TextInput
          value={values.name.value}
          label="Имя операции"
          on:input={(e) => handleChange("name", e.detail)}
        />
      </div>

      <div class="fieldWrap">
        <NumberInput
          disabled={!!values.productId.value}
          label="Количество коинов"
          value={values.amount.value}
          on:input={(e) => handleChange("amount", e.detail)}
        />
      </div>

      <div class="fieldWrap">
        {#each operationOptions as option}
          <FormField>
            <Radio
              disabled={values.productId.value}
              group={values.operation.value}
              value={option.operation}
              on:change={(e) => handleChange("operation", Number(e.target.value))}
            />
            <span slot="label">{option.name}</span>
          </FormField>
        {/each}
      </div>

      {#if hasError}    <p class="errorMsg">{error}</p> {/if}

      <footer>
        <Button on:click={handleSave} variant="raised">
          {editMode ? "Изменить транзакцию" : "Создать транзакцию"}
        </Button>
        {#if isSaveTransaction}
          <Spinner/>
        {/if}
      </footer>
    </Content>
  </Dialog>
</div>

<style lang="scss">
  .coinModal {
    .errorMsg {
      margin-bottom: 10px;
      color: red;
    }

    :global(.mdc-dialog__content) {
      overflow-y: hidden;
    }

    .fieldWrap {
      margin-bottom: 10px;

      :global(label) {
        width: 100%;
      }
    }

    footer {
      display: flex;
    }
  }
</style>
