import { stringify } from "query-string";
export const daysOffEndpoints = {
    root: "days-off",
    all(filters = {}) {
        let queryParams = stringify(filters);
        if (queryParams)
            queryParams = "?" + queryParams;
        return `/${this.root}${queryParams}`;
    },
    one(dayOffId, filters = {}) {
        let queryParams = stringify(filters);
        if (queryParams)
            queryParams = "?" + queryParams;
        return `/${this.root}/${dayOffId}${queryParams}`;
    },
};
