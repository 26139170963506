<script lang="typescript">
  import { Icon } from "@smui/common";
  import {formatter} from "helpers/formatter";

  export let coins:number = 0
  const selectRightForm = (n: number, text_forms: string[]): string => {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return text_forms[2];

    if (n1 > 1 && n1 < 5) return text_forms[1];

    if (n1 == 1) return text_forms[0];
    return text_forms[2];
  };
  const variants:string[] = [ "коин", "коина", "коинов" ]


</script>

<span>
   <Icon class="material-icons" style="color: #FFCC33">attach_money</Icon>
    {formatter.decimal(coins)} {selectRightForm(coins, variants)}
</span>

<style lang="scss">
 span {
   color: var(--violet);
   font-size: 12px;
   font-weight: bold;
   display: flex;
   align-items: center;
 }
</style>
