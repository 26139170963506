<script lang="typescript">
  import { onMount } from "svelte";
  import { invoicesStore } from "stores/invoicesStore";
  import InvoiceControls from "./InvoiceControls/index.svelte";
  import InvoiceModal from "./InvoiceModal/index.svelte";
  import InvoicesTable from "./InvoicesTable/index.svelte";

  const {
    getInvoiceStatuses,
    invoice,
    invoices,
    setDefaultFields,
    currentPositions,
    editId,
    emptyPosition,
    getInvoices,
  } = invoicesStore;

  let modal: { open: () => void; close: () => void };

  const handleModalClose = () => {
    modal.close();
    $editId = null;
    $invoice = setDefaultFields($invoice);
    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
  };

  const handleModalOpen = () => {
    modal.open();
  };

  const handleSubmitInvoice = () => {
    handleModalOpen();
  };

  onMount(async () => {
    await getInvoiceStatuses();
    getInvoices();
  });

</script>

<div class="invoices">
  <InvoiceControls isAbleToAdd hasFilters onCreate={handleSubmitInvoice} />
  <InvoiceModal bind:modal onModalClose={handleModalClose} />
  <div class="invoices__table">
    <InvoicesTable onModalOpen={handleModalOpen} />
  </div>
  {#if $invoices?.meta?.total && typeof $invoices?.meta?.sum === 'number'}
    <div class="invoices__meta">Всего {$invoices?.meta?.total} счетов на сумму {Math.floor($invoices?.meta?.sum).toLocaleString()} рублей.</div>
  {/if}
</div>

<style lang="scss">
  .invoices {
    margin-top: 15px;

    &__meta {
      margin-top: 10px;
    }

    &__table {
      margin-top: 15px;
    }

    :global(.mdc-dialog__surface) {
      min-width: 700px;
      max-height: 80vh;
    }
  }

</style>
