<script lang="typescript">
  import Button from '@smui/button';
  import { Icon } from '@smui/common';
  import SelectPeriod from "../../../shared/SelectPeriod/index.svelte"
  import SelectProject from "../../../shared/SelectProject/index.svelte"


  import { actsStore } from "stores/actsStore";
  import type { ActFiltersNormalize } from "interfaces/Act.interface"

  const {
    acts,
    getActs
  } = actsStore

  let dateTo = ""
  let dateFrom = ""
  let selectedProject;

  let filters: ActFiltersNormalize = {}

  const updateFilters = (newValues) => {
    filters = { ...filters, ...newValues }
  };

  const handleUseFilters = async () => {
    await getActs(filters)
  }

  const clearFilters = () => {
    dateTo = ""
    dateFrom = ""
    selectedProject = null
    filters = {}
  }
  const handleClearFilters = () => {
    clearFilters()
    handleUseFilters()
  }

  const handleSelectFilterProject = (event) => {
    updateFilters({ projectId: event.detail.id })
  }
  const handleSelectFilterPeriod = (event) => {
    if (event.detail.name === "dateTo") dateTo = event.detail.value
    else dateFrom = event.detail.value
    updateFilters({ [event.detail.name]: event.detail.value })
  }
  const handleClearFilterProject = () => {
    delete filters.projectId
  }

</script>

<div class="filters">
  <div class="wrap">
    <SelectProject
      on:clear={handleClearFilterProject}
      selectedValue={selectedProject}
      on:select={handleSelectFilterProject}/>
    <SelectPeriod
      on:select={handleSelectFilterPeriod}
      {dateTo}
      {dateFrom}/>
    <div class="btns">
      <Button variant="unelevated" on:click={handleUseFilters}>
        <Icon class="material-icons" style={`color:white;font-size: 25px; margin: -8px 0 0 -8px`}>
          search
        </Icon></Button>
      <Button variant="unelevated" on:click={handleClearFilters}>
        <Icon class="material-icons" style={`color:white;font-size: 25px;  margin: -8px 0 0 -8px`}>
          clear
        </Icon></Button>
    </div>
  </div>


</div>

<style lang="scss">
  .filters {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > :global(div) {
        margin-bottom: 10px;

        &:first-child {
          flex-grow: 1;

        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    :global(button) {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
</style>


