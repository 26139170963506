import { api } from "api/index";
import { endpoints } from "api/endpoints";
async function apiGetAccessCode(dataRequest) {
    var _a, _b;
    try {
        const { data } = await api({
            url: endpoints.auth.accessCode,
            method: 'POST',
            data: dataRequest
        });
        return data;
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const { success, message } = (_b = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _b === void 0 ? void 0 : _b.data;
            return {
                success,
                message: typeof message === "string" ? message : message.email.join("\n"),
            };
        }
    }
}
export default apiGetAccessCode;
