<script lang="ts">
  import Dialog, { Title } from "@smui/dialog";
  import Button from "@smui/button";
  import Textfield from "@smui/textfield";
  import type { IModal } from "interfaces/Common.interface";
  import type { EditStatusType, IChangeStatusModal } from "interfaces/Invoices.interface";
  import { format } from "date-fns";
  import { createEventDispatcher } from "svelte";

  // props
  export let item: IChangeStatusModal;
  export let modal: IModal | undefined;

  // modal
  const modalClose = () => modal?.close();

  // state
  let value = format(new Date(), "yyyy-MM-dd");
  $: error = new Date(value).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);

  // events
  const dispatch = createEventDispatcher<{ submit: EditStatusType }>();

  const handleInputChange = (e: Event) => {
    value = (e.target as HTMLInputElement).value;
  };

  const handleSubmit = async () => {
    dispatch("submit", {
      status_id: item.status_id,
      positions: item.formattedPositions,
      invoice_id: item.id,
      payment_date: value
    });
  };
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={modalClose}>
  <Title>Смена статуса на Оплачен для {item?.name}</Title>
  <div class="container">
    <div class="input">
      <Textfield
        invalid={error}
        required
        variant="outlined"
        type="date"
        label="Дата оплаты"
        bind:value
        on:input={(e) => handleInputChange(e)}
      />
      {#if error}
        <div class="red">Счет не может быть оплачен, если дата оплаты еще не наступила</div>
      {/if}
    </div>

    <div class="buttons">
      <Button disabled={error} on:click={handleSubmit}>Сохранить</Button>&nbsp;
      <Button color="secondary" on:click={modalClose}>Отмена</Button>
    </div>
  </div>
</Dialog>

<style scoped lang="scss">
  .container {
    margin: 20px;
  }

  .input {
    margin-bottom: 20px;
  }

  .red {
    color: var(--red);
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
</style>
