import { stringify } from "query-string";
import { actsFiltersToServer } from "../normalize/actsNormalize";
export const actsEndpoints = {
    root: "acts",
    log: "logs",
    status: "act-statuses",
    all(filters = {}) {
        let str = stringify(actsFiltersToServer(filters));
        str = str ? `?${str}` : str;
        return `${this.root}${str}`;
    },
    one(actId) {
        return `${this.root}/${actId}`;
    },
    logs(actId) {
        return `${this.root}/${actId}/${this.log}`;
    },
    statuses() {
        return `${this.status}`;
    }
};
