<script lang="typescript">
  import { push } from "svelte-spa-router";
  import Companies from "./Companies/index.svelte";
  import Invoices from "./Invoices/index.svelte";

  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import { Label } from "@smui/button";

  interface IParams {
    tab?: string;
  }

  interface ITabs {
    path: string;
    title: string;
  }

  const tabs: ITabs[] = [
    { path: "accounts", title: "Счета" },
    { path: "contractors", title: "Контрагенты" }
  ];

  export let params: IParams = {};
  let activeTab: ITabs;
  $: activeTab = tabs.find((t) => t.path === params?.tab) || tabs[0];

</script>

<div class="accounting">
  <h1 class="accounting__title">Финансовый учет</h1>
  <div class="accounting__tab-bar">
    <TabBar active={activeTab} let:tab {tabs}>
      <Tab minWidth on:click={() => push("/accounting/" + tab.path)} {tab}>
        <Label>{tab.title}</Label>
      </Tab>
    </TabBar>
  </div>

  {#if activeTab.path === "accounts"}
    <Invoices />
  {/if}
  {#if activeTab.path === "contractors"}
    <Companies />
  {/if}
</div>

<style lang="scss">
  .accounting {
    padding: 40px 30px 40px 0;

    &__title {
      margin-bottom: 12px;
      font-weight: normal;
      font-size: 48px;
      line-height: 56px;
    }

    &__tab-bar {
      padding-bottom: 10px;
    }
  }

</style>
