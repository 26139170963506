<script lang="typescript">
  import { onMount } from "svelte";

  import TopAppBar, { Row, Section } from "@smui/top-app-bar";

  import IconButton from "@smui/icon-button";

  import { link } from "svelte-spa-router";

  import { authStore } from "stores/authStore";
  import { notificationsStore } from "stores/notificationsStore";

  import Logo from "../../shared/Logo/index.svelte";
  import Bookmarks from "./Bookmarks/index.svelte"
  import Notification from "./Notification/index.svelte"


  export let isOpenMenu: boolean;

  const {
  } = notificationsStore
  const { logout } = authStore;

  function handleClickMenu() {
    isOpenMenu = !isOpenMenu;
  }

  function handleClickExitApp() {
    logout();
  }



</script>

<TopAppBar class="topBar" dense variant="fixed">
  <Row>
    <Section align="start">
      <IconButton class="material-icons topBarIcon" on:click={handleClickMenu}>
        menu
      </IconButton>
      <a class="logo" href="/" use:link>
        <Logo fontSize={18}/>
      </a>
    </Section>
    <Section class="_wide">
      <Bookmarks/>
    </Section>
    <Section align="end" toolbar>
     <Notification />

      <IconButton
        class="material-icons topBarIcon"
        on:click={handleClickExitApp}
      >
        exit_to_app
      </IconButton>
    </Section>
  </Row>
</TopAppBar>

<style lang="scss">


  :global(.topBar) {
    z-index: 5 !important;
    background-color: #fff !important;
    color: #000 !important;
    top: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);

    :global(.topBarIcon) {
      color: #000 !important;
    }

    :global(._wide) {
      flex-grow: 6;
    }

    .logo {
      &:hover {
        text-decoration: none;
      }
    }

  }

</style>
