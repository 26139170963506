<script lang="ts">
  import Select from "../../CustomSelect/index.svelte";
  import { adjustmentsStore } from "stores/adjustmentsStore";
  import { createEventDispatcher, onMount } from "svelte";
  export let value;
  const { adjustments, getAdjustemnts } = adjustmentsStore;

  const dispatch = createEventDispatcher();

  const handleSelect = (e: any) => {
    dispatch("changeValue", e.detail);
  };

  onMount(() => {
    if(!$adjustments) {
      getAdjustemnts()
    }
  })
</script>
<label for="">
  <Select 
    items={$adjustments ?? []}
    on:select={handleSelect}
    on:clear={handleSelect}
    placeholder="Выберите формат оформления"
    selectedValue={value}
  />
</label>

