export const normalizeNotification = (data) => {
    return {
        id: data.id,
        formattedData: data.formatted_data,
        isRead: data.is_read
    };
};
export const normalizeNotifications = (data) => {
    return data.map(normalizeNotification);
};
