import { api } from "api/index";
import { endpoints } from "api/endpoints";
import { liteMilestoneArrayNormalize } from "../../normalize/milestonesNormalize";
async function apiGetLiteMilestones(showArchived, filters = {}) {
    var _a;
    try {
        const { data: { success, message }, } = await api({
            url: endpoints.milestones.all(filters),
            method: "GET",
            headers: showArchived ? { "Show-archived": showArchived } : null,
        });
        return { success, data: liteMilestoneArrayNormalize(message) };
    }
    catch (error) {
        const errorAxios = error;
        if ((_a = errorAxios === null || errorAxios === void 0 ? void 0 : errorAxios.response) === null || _a === void 0 ? void 0 : _a.data) {
            const errorAxios = error;
            if (errorAxios.response.data) {
                const { success, message } = errorAxios.response.data;
                return {
                    success,
                    message,
                };
            }
        }
    }
}
export default apiGetLiteMilestones;
