import { __rest } from "tslib";
import { hoursNormalize } from "./hoursNormalize";
import { projectNormalize } from "./projectNormalize";
import { userCostArrayNormalize } from "./userCostNormalize";
function liteMilestoneNormalize(data) {
    const { id, name, billing_type, fact_revenue, is_guarantee, max_billing_hours, plan_revenue, redmine_id, sort, spent_hours, status, project, plan_production_profitability, fact_production_profitability, production_cost, is_archived, plan_total_profitability, fact_total_profitability, finish_date, tracking_start, tracking_end, } = data;
    return {
        id,
        name,
        sort,
        status,
        billingType: billing_type,
        factRevenue: fact_revenue,
        isGuarantee: is_guarantee,
        maxBillingHours: max_billing_hours,
        planRevenue: plan_revenue,
        revenue: fact_revenue || plan_revenue || 0,
        typeRevenue: fact_revenue ? "factRevenue" : "planRevenue",
        redmineId: redmine_id,
        spentHours: hoursNormalize(spent_hours),
        project: project ? projectNormalize(project) : null,
        planProductionProfitability: plan_production_profitability,
        factProductionProfitability: fact_production_profitability,
        productionCost: production_cost,
        isArchived: is_archived,
        factTotalProfitability: fact_total_profitability,
        planTotalProfitability: plan_total_profitability,
        totalProfitability: fact_total_profitability || plan_total_profitability,
        finish_date,
        tracking_start: tracking_start || '',
        tracking_end: tracking_end || '',
    };
}
function detailMilestoneNormalize(milestone) {
    const { user_data, additional_costs, fixed_costs, other_costs } = milestone, liteMilestone = __rest(milestone, ["user_data", "additional_costs", "fixed_costs", "other_costs"]);
    const normalizedLiteMilestone = liteMilestoneNormalize(liteMilestone);
    const extension = {
        userData: userCostArrayNormalize(user_data),
        additionalCosts: additional_costs,
        fixedCosts: fixed_costs,
        otherCosts: other_costs
    };
    return Object.assign(normalizedLiteMilestone, extension);
}
function liteMilestoneArrayNormalize(array) {
    return array.map(liteMilestoneNormalize);
}
function editedParamsNormalizeToServer(changedParams) {
    const normalizeMap = {
        statusId: "status_id",
        sort: "sort",
        billingType: "billing_type",
        maxBillingHours: "max_billing_hours",
        isGuarantee: "is_guarantee",
        planRevenue: "plan_revenue",
        factRevenue: "fact_revenue",
        isArchived: "is_archived",
        finish_date: "finish_date",
        tracking_start: "tracking_start",
        tracking_end: "tracking_end"
    };
    const serverNormalizedParams = {};
    for (let key in changedParams) {
        if (changedParams.hasOwnProperty(key)) {
            serverNormalizedParams[normalizeMap[key]] = changedParams[key];
        }
    }
    return serverNormalizedParams;
}
export { editedParamsNormalizeToServer, liteMilestoneNormalize, liteMilestoneArrayNormalize, detailMilestoneNormalize };
