<script lang="typescript">
  export let fontSize: number = 36;
</script>

<div class="logo" style={`font-size: ${fontSize}px`}>
  <span class="yo">YO!</span> DIGITAL SPECTR
</div>

<style lang="scss">
  .logo {
    line-height: 1.2;
  }

  .yo {
    color: #3c67ff;
  }
</style>
