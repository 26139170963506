<script lang="typescript">
  import Dialog, { Title } from "@smui/dialog";
  import type { IModal } from "interfaces/Common.interface";
  import InvoiceForm from "../InvoiceForm/index.svelte";
  export let modal: IModal | undefined;
  export let onModalClose: () => void;
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={onModalClose}>
  <Title>Добавление счёта</Title>
  <InvoiceForm {onModalClose} />
</Dialog>
