<script lang="typescript">
  //@ts-nocheck
  import { formatter } from "helpers/formatter";
  import DaysOff from "./DaysOff/index.svelte";
  import CoinTable from "../../shared/CoinTable/index.svelte";
  import Spinner from "../../shared/Spinner/index.svelte";

  import { authStore } from "stores/authStore";
  import { usersStore } from "stores/usersStore";
  import type { MeNormalizeInterface } from "interfaces/User.interface";
  import {onDestroy} from "svelte";

  const { userData } = authStore;
  const { getPersonalTransactions, coinTransactions, isFetchTransactions } = usersStore;

  $: $userData?.id && getPersonalTransactions($userData.id);

  function getTransactions(userData: MeNormalizeInterface) {
    if (userData) getPersonalTransactions(userData.id);
  }

  onDestroy(() => {
    $coinTransactions = null
  })
</script>

<section class="personal-page">
  <div class="mdc-typography--headline3 personal-page__row _mb-big">{$userData?.name}</div>
  <div class="mdc-typography--headline5 personal-page__row">{$userData?.position ?? ""}</div>
  {#if $userData?.billingType}
    <div class="personal-page__row">
      {$userData?.billingType === "TM" ? "Почасовая" : "Фиксированная"}
      оплата труда - {$userData?.rate} рублей
    </div>
  {/if}
  <DaysOff />

  <h2 class="mdc-typography--headline4 personal-page__headline">
    Коины. Баланс: {#if $userData}
      {$userData && typeof $userData.coins == 'number' ? formatter.coins($userData.coins) : "---"}
    {:else}
      <Spinner />
    {/if}
  </h2>
  {#if $isFetchTransactions}
    <Spinner />
  {:else if $coinTransactions && $coinTransactions?.length}
    <CoinTable coinTransactions={$coinTransactions || []} />
  {:else}
    <p>Транзакции не найдены</p>
  {/if}
</section>

<style lang="scss">
  .personal-page {
    padding: 30px 30px 30px 0;

    &__row {
      margin-bottom: 10px;

      &._mb-big {
        margin-bottom: 20px;
      }
    }

    &__headline {
      margin-bottom: 20px;
    }
  }
</style>
