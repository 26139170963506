<script lang="typescript">
  import { onMount } from "svelte";
  import { link, push } from "svelte-spa-router";

  import DataTable, { Body, Cell, Head, Row } from '@smui/data-table';
  import IconButton from "@smui/icon-button";


  import Spinner from "../../shared/Spinner/index.svelte"

  import { usersStore } from "stores/usersStore";
  import { authStore } from "stores/authStore";

  import { Paths } from "constants/path";

  const { hasPermission } = authStore;

  const {
    getUsersDetailed,
    usersDetailed,
    error,
    isFetchUsers
  } = usersStore

  onMount(() => {
    if (hasPermission([ "users_access", "users_access_money" ])) {
      if (!$usersDetailed?.length) getUsersDetailed()
    } else push(Paths.main);
  });

</script>

<section>
  {#if $isFetchUsers}
    <div class="_with-air">
      <Spinner/>
    </div>
  {:else if !$isFetchUsers && $usersDetailed.length > 0}
    <DataTable style="width: 100%">
      <Head>
        <Row>
          <Cell>Пользователь</Cell>
          <Cell>Должность</Cell>
          <Cell>Койны</Cell>
          {#if hasPermission("users_access_money")}
            <Cell>Формат оплаты</Cell>
            <Cell>Формат оформления</Cell>
            <Cell>Рейт</Cell>
          {/if}
          <Cell>Проектный офис</Cell>
          <Cell/>
        </Row>
      </Head>
      <Body>
      {#each $usersDetailed as user (user.id)}
        <Row>
          <Cell>{user.name}</Cell>
          <Cell>{user.position ?? "-"}</Cell>
          <Cell>{user.coins ?? 0}</Cell>
          {#if hasPermission("users_access_money")}
            <Cell>{user.billingType ?? "-"}</Cell>
            <Cell>{user.adjustment && user.adjustment.name || '-'}</Cell>
            <Cell>{user.rate ?? "-"}</Cell>
          {/if}
          <Cell>{user?.projectOffice?.name ?? ""}</Cell>
          <Cell numeric>
            <a href={`/users/${user.id}`} use:link>
              <IconButton class="material-icons mode_edit">
                mode_edit
              </IconButton>
            </a>
          </Cell>
        </Row>
      {/each}
      </Body>
    </DataTable>
  {:else}
    <p class="error-text">{$error ?? "Нет данных по вашему запросу"}</p>
  {/if}


</section>

<style lang="scss">
  .error-text {
    text-align: center;
    padding: 10px 0;
  }


  :global(._with-air) {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
