import { get, writable, derived } from "svelte/store";
import { apiGetUsers } from "api/methods/users/apitGetUsers";
import { rolesStore } from "./rolesStore";
// данный стор хранит список проджект и аккаунт манагеров
const managers = writable([]);
const isFetchManagers = writable(false);
const error = writable(null);
const { getRoles, roles } = rolesStore;
const getManagers = async () => {
    var _a;
    isFetchManagers.set(true);
    if (!((_a = get(roles)) === null || _a === void 0 ? void 0 : _a.length)) {
        await getRoles();
    }
    const managersList = ["account_manager", "project_manager"];
    const managersID = get(roles).reduce((acc, cur) => {
        if (managersList.includes(cur.name))
            acc.push(cur.id);
        return acc;
    }, []);
    const { success, data, message } = await apiGetUsers({ role: managersID });
    isFetchManagers.set(false);
    if (success) {
        error.set(null);
        managers.set(data);
    }
    else {
        error.set(message);
        managers.set([]);
    }
};
const accountManagers = derived(managers, ($managers) => $managers.filter(item => item.roles.includes("account_manager")));
const projectManagers = derived(managers, ($managers) => $managers.filter(item => item.roles.includes("project_manager")));
export const managersStore = {
    managers,
    getManagers,
    projectManagers,
    accountManagers
};
