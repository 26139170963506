import { __rest } from "tslib";
function otherCostNormalize(costFromServer) {
    const { milestone_id } = costFromServer, otherProps = __rest(costFromServer, ["milestone_id"]);
    return Object.assign(otherProps, { milestoneId: milestone_id });
}
function otherCostArrayNormalize(otherCosts) {
    return otherCosts.map(otherCostNormalize);
}
function prepareFilters(filter) {
    const { dateFrom, dateTo, milestoneId } = filter;
    return { date_from: dateFrom, date_to: dateTo, milestone_id: milestoneId };
}
export { otherCostArrayNormalize, otherCostNormalize, prepareFilters };
