<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import Textfield from "@smui/textfield";

  export let value = "";
  export let label = "Дата";

  const dispatch = createEventDispatcher();

  const handleChange = (e: Event) => dispatch("changeValue", (e.target as HTMLInputElement).value);
</script>

<label for="">
  <Textfield
    {value}
    {label}
    variant="outlined"
    on:change={handleChange}
    type="date"
    input$id="modalDatePicker"
  />
</label>
