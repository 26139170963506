<script lang="typescript">
  import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";

  import Button from "@smui/button";
  import Dialog, { Content, Title } from "@smui/dialog";

  import TextInput from "../../shared/Fields/Text.svelte";
  import RolesSelect from "../../shared/Fields/SelectRoles/index.svelte";
  import NumberInput from "../../shared/Fields/Number.svelte";
  import Date from "../../shared/Fields/Date.svelte";
  import Spinner from "../../shared/Spinner/index.svelte";
  import EditingButton from "../../shared/Button/Editing/index.svelte";
  import SelectBillingType from "../../shared/Fields/SelectBillingType/index.svelte";
  import SelectAdjustments from "../../shared/Fields/SelectAdjustments/index.svelte";
  import SelectProjectOffice from "../../shared/Fields/SelectProjectOffice/index.svelte";

  import { authStore } from "stores/authStore";
  import { usersStore } from "stores/usersStore";
  const { hasPermission } = authStore;

  const {
    user,
    updateUser,
    updateUserStore,
    updateUsersStore,
    updateUsersDetailedStore,
    users,
    usersDetailed
  } = usersStore;

  let isCanEdit = hasPermission(["users_access", "users_access_money"]);
  let isCanEditCommercial = hasPermission(["users_access_money"]);

  let modal;
  let activeField: any = null;
  let activeValue: any = null;
  let defaultValue: any = null;
  let isUpdatingUser: any = false;
  const setActiveField = (name, component, baseValue, nameForServer, label, props = {}) => {
    activeField = { name, component, props, nameForServer, label };
    defaultValue = baseValue;
    activeValue = baseValue;
    modal.open();
  };

  const handleChange = (value) => {
    activeValue = value;
  };

  function handleCloseModal() {

    modal.close();
    activeField = null;
    activeValue = null;
    defaultValue = null;
  }

  const handleSave = async (name, value) => {
    let valueToServer = value;
    if (Array.isArray(value)) {
      if (value.length === 0) return;
      else {
        valueToServer = value.map((item) => item.id);
        value = value.map((item) => item.name);
      }
    } else if (value && typeof value === "object") {
      valueToServer = value.id;
    }
    const data = { [name]: valueToServer };
    if ($user?.id) {
      //@ts-ignore
      const { success, message } = await updateUser(data, $user.id);
      if (success) {
        updateUserStore(value, activeField.name);
        //@ts-ignore
        if ($users?.length) updateUsersStore(value, activeField.name, $user.id);
        //@ts-ignore
        if ($usersDetailed?.length) updateUsersDetailedStore(value, activeField.name, $user.id);
        handleCloseModal();
      } else notifier.danger(message, 2000);
    }
  };

  $: btnsInColumn = [
    {
      name: "Email",
      field: "email",
      label: "Введите email",
      fieldForServer: "email",
      value: $user?.email ?? "",
      btnName: $user?.email,
      isEditable: isCanEdit,
      component: TextInput,
      props: {},
      baseValue: "Не указано"
    },
    {
      name: "Позиция",
      field: "position",
      label: "Введите позицию",
      fieldForServer: "position",
      value: $user?.position ?? "",
      btnName: $user?.position,
      isEditable: isCanEdit,
      component: TextInput,
      props: {},
      baseValue: "Не указано"
    },
    {
      name: "Роли",
      field: "roles",
      label: "Выберите роли",
      fieldForServer: "roles",
      value: $user?.roles,
      btnName: $user?.roles?.join(", "),
      isEditable: isCanEdit,
      component: RolesSelect,
      props: {
        defaultValue: $user?.roles
      },
      baseValue: "Не указано"
    },
    {
      name: "ID в Redmine",
      field: "redmineId",
      fieldForServer: "redmine_id",
      label: "ID",
      value: $user?.redmineId,
      btnName: $user?.redmineId,
      isEditable: isCanEdit,
      component: NumberInput,
      props: {},
      baseValue: "Не указано"
    },
    {
      name: "Баланс коинов",
      field: "coins",
      label: "Койны",
      fieldForServer: "coins",
      value: $user?.coins ?? 0,
      btnName: $user?.coins,
      isEditable: false,
      component: NumberInput,
      props: {},
      baseValue: "Не указано"
    },
    {
      name: "Ежедневная плановая выработка",
      field: "dayHours",
      label: "Выработка",
      fieldForServer: "day_hours",
      value: $user?.dayHours ?? "",
      btnName: $user?.dayHours,
      isEditable: isCanEdit,
      component: TextInput,
      props: {},
      baseValue: "Не указано"
    },
    {
      name: "Формат оплаты",
      field: "billingType",
      fieldForServer: "billing_type",
      label: "Выберите формат",
      value: $user?.billingType,
      btnName: $user?.billingType,
      isEditable: isCanEditCommercial,
      component: SelectBillingType,
      baseValue: "Не указано",
      props: {
        selectedValue: { name: $user?.billingType, id: $user?.billingType }
      }
    },
    {
      name: "Формат оформления",
      field: "adjustment",
      fieldForServer: "adjustment_id",
      label: "Выберите формат",
      value: $user?.adjustment,
      btnName: $user?.adjustment?.name,
      isEditable: isCanEditCommercial,
      component: SelectAdjustments,
      baseValue: "Не указано",
    },
    {
      name: "Ставка специалиста",
      field: "rate",
      fieldForServer: "rate",
      label: "Выберите число",
      value: $user?.rate,
      btnName: $user?.rate ?? 0,
      isEditable: isCanEditCommercial,
      component: NumberInput,
      props: {},
      baseValue: "Не указано"
    },
    {
      name: "Проектный офис",
      field: "projectOffice",
      label: "Выберите офис",
      fieldForServer: "project_office_id",
      value: $user?.projectOffice,
      btnName: $user?.projectOffice?.name,
      isEditable: isCanEdit,
      component: SelectProjectOffice,
      baseValue: "Не указано"
    },
    {
      name: "Дата приёма",
      field: "hiringDate",
      label: "Установите дату",
      fieldForServer: "hiring_date",
      value: $user?.hiringDate ?? '',
      btnName: $user?.hiringDate,
      isEditable: isCanEdit,
      component: Date,
      baseValue: ""
    },
    {
      name: "Дата увольнения",
      field: "terminationDate",
      label: "Установите дату",
      fieldForServer: "termination_date",
      value: $user?.terminationDate ?? '',
      btnName: $user?.terminationDate,
      isEditable: isCanEdit,
      component: Date,
      baseValue: ""
    }
  ];
</script>

<NotificationDisplay />
<section class="section user">
  {#if isUpdatingUser}
    <div class="_with-air">
      <Spinner />
    </div>
  {:else if $user}
    <header>
      <h2 class="mdc-typography--headline4 ">{$user?.name}</h2>
    </header>
    <div class="buttons-column">
      {#each btnsInColumn as { name, field, label, isEditable, fieldForServer, value, component, props, btnName, baseValue }}
        <div class="row">
          <span>{name}</span>
          {#if isEditable}
            <EditingButton
              disabled={false}
              on:click={() => {
                setActiveField(field, component, value, fieldForServer, label, props);
              }}
            >
              {btnName ?? baseValue}
            </EditingButton>
          {:else}
            <EditingButton>
              {btnName ?? baseValue}
            </EditingButton>
          {/if}
        </div>
      {/each}
    </div>
    <Dialog on:MDCDialog:closed={handleCloseModal} bind:this={modal}>
      {#if isUpdatingUser}
        <Content>
          <Spinner />
        </Content>
      {:else if activeField}
        <Title>Редактировать данные пользователя</Title>
        <Content>
          <!--            Сюда должен прилетать компонент, который эмитит событие changeValue с валидным значением -->
          <svelte:component
            this={activeField.component}
            value={defaultValue}
            label={activeField.label}
            on:changeValue={(event) => handleChange(event.detail)}
            {...activeField.props}
          />
        </Content>
        <footer class="buttons">
          <Button
            on:click={() => handleSave(activeField.nameForServer, activeValue)}
            disabled={false}
          >
            Сохранить
          </Button>
        </footer>
      {/if}
    </Dialog>
  {/if}
</section>

<style lang="scss">
  .user {
    :global(.mdc-dialog__title + .mdc-dialog__content) {
      padding-top: 10px;
    }

    :global(.mdc-dialog__surface),
    :global(.mdc-dialog__content) {
      overflow-y: visible;
      overflow: inherit;
    }

    :global(.mdc-text-field) {
      width: 100%;
    }
  }
  .buttons-column {
    display: inline-block;
    flex-direction: column;
  }

  header {
    margin-bottom: 20px;
  }

  .row {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > span {
      margin-right: 10px;
    }
  }

  footer {
    &.buttons {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 15px;
    }
  }
</style>
