<script lang="typescript">
  import { onDestroy } from "svelte";

  import { push } from "svelte-spa-router";
  import { Icon } from "@smui/common"
  import Button, { Label } from '@smui/button';
  import Dialog, { Content, Title } from '@smui/dialog';
  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";

  import DateField from "../../Fields/Date.svelte"
  import StatusId from "../../Fields/StatusId.svelte"
  import IsApprovedField from "../../Fields/IsApproved.svelte"
  import InputNumber from "../../Fields/Number.svelte"
  import Scan from "../../Fields/Scan.svelte"
  import MilestoneId from "../../Fields/MilestoneId.svelte"
  import LogsTable from "../../../shared/LogsTable/index.svelte";

  import { actsStore } from "stores/actsStore";
  import { authStore } from "stores/authStore";

  import Spinner from "../../Spinner/index.svelte"
  import EditingButton from '../../Button/Editing/index.svelte';

  import { Paths } from "constants/path";
  import { formatter } from "helpers/formatter";

  type IProps = { value?: string }
  export let props = {} as IProps

  const { getAct, act, isUpdatingAct, updateAct } = actsStore;
  const { hasPermission } = authStore;

  let isHasPermissionEdit = hasPermission([ "acts_update", "acts_full_access" ]);
  let isCanApprove = hasPermission([ "acts_can_approve", "acts_full_access" ]);
  let isHasAccessCommercial = hasPermission([ "acts_read_commercial_info", "acts_full_access" ]);

  let modal: any;
  let activeField: any = null;
  let activeValue: any = null;
  let defaultValue: any = null;

  const INFO_TAB = "Инфо";
  const CHANGES_TAB = "История изменений";
  let tabs = [ INFO_TAB, CHANGES_TAB ];
  let active: any;


  const setActiveField = (name: any, component: any, baseValue: any, props = {}) => {
    activeField = { name, component, props }
    defaultValue = baseValue
    activeValue = baseValue
    modal.open()
  }

  const handleChange = (_name: string, value: string) => {
    activeValue = value
  }
  const handleSave = async (name: string, value: string) => {
    //@ts-ignore
    $act && $act?.id && updateAct({ [name]: value }, $act?.id)
  }

  const handleCloseModal = () => {
    activeField = null;
    activeValue = null;
    defaultValue = null;
  }

  const checkHasUpdate = (defaultValue: any, activeValue: any) => activeValue !== null && defaultValue !== activeValue

  $: approveText = $act?.isApproved ? "Акт подтвержден" : "Акт не подтвержден"
  $: if (props && isFinite(Number(props.value))) {
    isUpdatingAct.set(true)
    //@ts-ignore
    getAct(+props.value)
      .then(success => {
        if (!success) push(Paths.acts)
        else isUpdatingAct.set(false)
      })
  }

  $: btnsInColumn = [
    {
      name: "Сумма акта",
      field: "price",
      value: $act?.price,
      btnName: `${ formatter.money($act?.price ?? 0) }`,
      isShow: $act?.price,
      disabled: !isHasPermissionEdit || !isHasAccessCommercial,
      component: InputNumber,
      props: {
        label: "Сумма акта"
      },
    },
    {
      name: "Часы, закрытые актом",
      field: "hours",
      value: $act?.hours,
      btnName: `${ $act?.hours }`,
      isShow: true,
      disabled: !isHasPermissionEdit,
      component: InputNumber,
      props: {
        label: "Часы"
      },
    },
    {
      name: "Проект, веха",
      field: "milestoneId",
      value: $act?.project.id,
      btnName: `${ $act?.project.name }, ${ $act?.milestone.name }`,
      isShow: true,
      disabled: !isHasPermissionEdit,
      component: MilestoneId,
      props: {
        selectedValue: {
          project: $act?.project,
          milestone: $act?.milestone,
        }
      }
    },
  ]
  $: btnsInRow = [
    {
      name: `${ $act?.date ?? "Дата" }`,
      value: $act?.date,
      field: `date`,
      component: DateField,
      disabled: !isHasPermissionEdit
    },
    {
      name: `${ $act?.status?.name ?? "Статус" }`,
      value: $act?.status.id,
      field: `statusId`,
      component: StatusId,
      disabled: !isHasPermissionEdit,
      props: {
        selectedValue: $act?.status
      }
    }
  ]

  $: hasUpdate = checkHasUpdate(defaultValue, activeValue)

  onDestroy(() => {
    activeField = null
    activeValue = null
    defaultValue = null
    act.set(null)
  })

</script>

<TabBar bind:active let:tab {tabs}>
  <Tab minWidth {tab}>
    <Label>{tab}</Label>
  </Tab>
</TabBar>
{#if active === INFO_TAB}
  <section class="section act-detail">
    {#if $isUpdatingAct}
      <div class="_with-air">
        <Spinner/>
      </div>
    {:else if $act}
      <header>
        <div>
          <h2 class="mdc-typography--headline4 "> {$act?.name} </h2>
          {#each btnsInRow as {value, name, field, component, disabled, props}}
            <EditingButton class="editBtn"
                           variant="outlined"
                           {disabled}
                           on:click={()=> {setActiveField(field, component, value, props)}}>
              {name}
            </EditingButton>
          {/each}
        </div>
        <div>
          <EditingButton class={`editBtn ${$act?.isApproved ? "_green" : "_red"}`}
                         disabled={!isHasPermissionEdit || !isCanApprove}
                         on:click={()=> {setActiveField("isApproved", IsApprovedField, $act?.isApproved, { disabled: !isCanApprove})}}>
            {approveText}
          </EditingButton>
        </div>
      </header>
      <div>
        {#each btnsInColumn as {isShow, value, name, component, field, props, disabled, btnName}}
          {#if isShow}
            <div class="row">
              <span>{name}</span>
              <EditingButton
                {disabled}
                on:click={()=> {setActiveField(field, component, value, props)}}>
                {btnName}
              </EditingButton>
            </div>
          {/if}
        {/each}
        <div class="row">
          <Icon class="material-icons" style={`font-size: 30px; margin-right: 2px`}>
            content_copy
          </Icon>
          <span>Сканы подписанного акта</span>
          {#if $act?.scanFile}
            <a href={$act?.scanFile || ""} target="_blank" rel="noreferrer noopener" download title="Скачать">
              <Button class="_mr30"
                      variant="outlined">
                <Icon class="material-icons icon__btn _ml8">download</Icon>
              </Button>
            </a>
          {/if}
          <Button disabled={!isHasPermissionEdit || !isHasAccessCommercial}
                  on:click={()=> {setActiveField("scanFile", Scan, $act?.scanFile)}}
                  variant="outlined">
            <Icon class="material-icons icon__btn _ml8">mode_edit</Icon>
          </Button>
        </div>
      </div>
      <Dialog
        on:MDCDialog:closed={handleCloseModal}
        bind:this={modal}
      >
        {#if $isUpdatingAct}
          <Content>
            <Spinner/>
          </Content>
        {:else if activeField}
          <Title>Редактировать акт</Title>
          <Content>
            <!--Сюда должен прилетать компонент, который эмитит событие changeValue с валидным значением -->
            <svelte:component this={activeField.component}
                              value={defaultValue}
                              on:changeValue={(event) => handleChange(activeField.name, event.detail)}
                              {...activeField.props}/>
          </Content>
          <footer class="buttons">
            <Button on:click={() => handleSave(activeField.name, activeValue)}
                    disabled={!isHasPermissionEdit || $isUpdatingAct || !hasUpdate}>
              Сохранить
            </Button>
          </footer>
        {/if}

      </Dialog>
    {/if}
  </section>

{:else if active === CHANGES_TAB && $act && $act?.id}
  <div class="changesTable">
    <LogsTable params={{ type: "acts", id: $act?.id || 0 }} />
  </div>

{/if}


<style lang="scss">
  .changesTable {
    margin: 30px 0;
  }
  .act-detail {
    width: 80vw;

    :global(.mdc-dialog__content label) {
      width: 100%;
    }

    :global(.editBtn) {
      margin-left: 30px;
      margin-top: 5px;

      &._red {
        color: var(--red)
      }

      &._green {
        color: var(--green)
      }
    }

    :global(._mr30) {
      margin-right: 30px;
    }

    header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: baseline;
      }
    }

    :global(.mdc-dialog__surface), :global(.mdc-dialog__content) {
      overflow-y: visible;
      overflow: inherit;

    }

    .row {
      display: flex;
      padding-top: 10px;
      align-items: center;

      span {
        margin-right: 30px;
      }
    }
  }

  footer {
    &.buttons {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 15px;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

</style>
